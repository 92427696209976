import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "search-result-default-main" }
const _hoisted_2 = { class: "default-text" }

import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC } from '../../../../utils/env';
import Icon from '../../../common/Icon.vue';
import SearchDefaultIcon from '../../../../assets/icon/search-default.svg';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['search-result-default', !_unref(isPC) && 'search-result-default-h5'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Icon, {
        file: _unref(SearchDefaultIcon),
        width: "88px",
        height: "75px"
      }, null, 8, ["file"]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t("TUISearch.暂无搜索结果")), 1)
    ])
  ], 2))
}
}

})