import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tui-search-header"
}
const _hoisted_2 = { class: "tui-search-header-title" }
const _hoisted_3 = { class: "tui-search-tabs" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "tui-search-time"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "tui-search-time-item-picker"
}
const _hoisted_8 = { key: 1 }

import { ref, computed, onMounted, onUnmounted } from '../../../adapter-vue';
import {
  TUITranslateService,
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import { Dayjs } from 'dayjs';
import {
  globalSearchTypeList,
  conversationSearchTypeList,
  searchMessageTypeDefault,
} from '../search-type-list';
import { searchMessageTimeList, searchMessageTimeDefault } from '../search-time-list';
import Icon from '../../common/Icon.vue';
import DatePicker from '../../common/DatePicker/index.vue';
import downArrowIcon from '../../../assets/icon/down-icon.svg';
import closeIcon from '../../../assets/icon/input-close.svg';
import closeDarkIcon from '../../../assets/icon/close-dark.svg';
import { isPC, isUniFrameWork } from '../../../utils/env';
import { SEARCH_TYPE, ISearchMessageTime, ISearchMessageType, ISearchTimeTab, ISearchTypeTab } from '../type';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  popupPosition: {
    type: String, // "bottom" / "aside"
    default: 'bottom',
  },
  searchType: {
    type: String,
    default: 'global', // "global" / "conversation"
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    },
  },
},
  emits: ['searchConfigChange', 'closeInConversationSearch'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const searchTypeList = computed(() =>
  props?.searchType === 'conversation' ? conversationSearchTypeList : globalSearchTypeList,
);
const currentSearchMessageType = ref(searchMessageTypeDefault[props?.searchType as SEARCH_TYPE]);
const currentSearchMessageTime = ref(searchMessageTimeDefault);

const isTimeTabsShow = computed(() => {
  return (
    currentSearchMessageType.value.key !== 'contact'
    && currentSearchMessageType.value.key !== 'group'
  );
});
const datePickerRangeDisplayType = computed((): string =>
  isPC && props.searchType === 'global' && !isUniFrameWork ? 'two' : 'one',
);
const isDatePickerShow = ref<boolean>(false);

function onCurrentSearchMessageTypeChange(typeObject: ISearchMessageType) {
  if (typeObject?.searchType === props?.searchType) {
    currentSearchMessageType.value
        = typeObject?.value || searchMessageTypeDefault[props?.searchType as SEARCH_TYPE];
  }
}

function onCurrentSearchMessageTimeChange(timeObject: ISearchMessageTime) {
  if (timeObject?.searchType === props?.searchType) {
    currentSearchMessageTime.value = timeObject?.value || searchMessageTimeDefault;
  }
}

onMounted(() => {
  TUIStore.watch(StoreName.SEARCH, {
    currentSearchMessageType: onCurrentSearchMessageTypeChange,
    currentSearchMessageTime: onCurrentSearchMessageTimeChange,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.SEARCH, {
    currentSearchMessageType: onCurrentSearchMessageTypeChange,
    currentSearchMessageTime: onCurrentSearchMessageTimeChange,
  });
});

const selectSearchType = (item: ISearchTypeTab) => {
  TUIStore.update(StoreName.SEARCH, 'currentSearchMessageType', {
    value: item,
    searchType: props.searchType,
  });
};

const selectSearchTime = (item: ISearchTimeTab) => {
  if (isDatePickerShow.value && item.key === 'all') {
    isDatePickerShow.value = false;
  } else {
    isDatePickerShow.value = false;
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
      value: item,
      searchType: props.searchType,
    });
  }
};

const handleSelectAllTimeClicked = () => {
  if (currentSearchMessageTime.value?.key !== 'all') {
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
      value: searchMessageTimeDefault,
      searchType: props.searchType,
    });
  } else {
    isDatePickerShow.value = true;
  }
};

const pickTimePeriod = (time: typeof Dayjs) => {
  if (currentSearchMessageTime.value?.key === 'all') {
    const { startDate, endDate } = time;
    const timePosition = Number((endDate?.toDate()?.getTime() / 1000).toFixed(0));
    const timePeriod = timePosition - Number((startDate?.toDate()?.getTime() / 1000).toFixed(0));
    const newSearchMessageTime = {
      key: currentSearchMessageTime.value.key,
      label: currentSearchMessageTime.value.label,
      value: {
        timePosition,
        timePeriod,
      },
    };
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
      value: newSearchMessageTime,
      searchType: props.searchType,
    });
  }
};

const clearTimePicker = () => {
  isDatePickerShow.value = false;
  if (currentSearchMessageTime.value?.key === 'all') {
    TUIStore.update(StoreName.SEARCH, 'currentSearchMessageTime', {
      value: searchMessageTimeDefault,
      searchType: props.searchType,
    });
  }
};

const closeSearchContainer = () => {
  emits('closeInConversationSearch');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'tui-search-container',
      !_unref(isPC) && 'tui-search-container-h5',
      _unref(isPC) && `container-${props.popupPosition}`,
      `container-${props.searchType}`,
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        _unref(isPC) && `tui-search-container-${props.popupPosition}`,
        !_unref(isPC) && 'tui-search-container-h5-main',
      ])
    }, [
      (props.searchType === 'conversation' && !_unref(isUniFrameWork))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t("TUISearch.搜索会话内容")), 1),
            _createElementVNode("div", {
              class: "tui-search-header-close",
              onClick: closeSearchContainer
            }, [
              _createVNode(Icon, {
                file: _unref(closeDarkIcon),
                width: "14px",
                height: "14px"
              }, null, 8, ["file"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchTypeList), (tabItem, tabKey) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tabKey,
            class: _normalizeClass([
            'tui-search-tabs-item',
            _unref(currentSearchMessageType).key === tabItem.key && 'tui-search-tabs-item-selected',
          ]),
            onClick: ($event: any) => (selectSearchType(tabItem))
          }, _toDisplayString(_unref(TUITranslateService).t(`TUISearch.${tabItem.label}`)), 11, _hoisted_4))
        }), 128))
      ]),
      _renderSlot(_ctx.$slots, "input"),
      (_unref(isTimeTabsShow))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchMessageTimeList), (tabItem, tabKey) => {
              return (_openBlock(), _createElementBlock("div", {
                key: tabKey,
                class: _normalizeClass([
            'tui-search-time-item',
            _unref(currentSearchMessageTime).key === tabItem.key && 'tui-search-time-item-selected',
          ]),
                onClick: ($event: any) => (selectSearchTime(tabItem))
              }, [
                (tabItem.key === 'all')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (!_unref(isDatePickerShow))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "tui-search-time-item-all",
                            onClick: _withModifiers(handleSelectAllTimeClicked, ["stop"])
                          }, [
                            _createTextVNode(_toDisplayString(_unref(TUITranslateService).t(`TUISearch.选择时间`) +
                  ":  " +
                  _unref(TUITranslateService).t(`TUISearch.全部`)) + " ", 1),
                            _createVNode(Icon, {
                              file: _unref(downArrowIcon),
                              width: "14px",
                              height: "14px"
                            }, null, 8, ["file"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        (_unref(isDatePickerShow))
                          ? (_openBlock(), _createBlock(DatePicker, {
                              key: 0,
                              type: "range",
                              rangeTableType: _unref(datePickerRangeDisplayType),
                              onPick: pickTimePeriod
                            }, null, 8, ["rangeTableType"]))
                          : _createCommentVNode("", true)
                      ]),
                      (_unref(isDatePickerShow))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "tui-search-time-item-close",
                            onClick: clearTimePicker
                          }, [
                            _createVNode(Icon, {
                              class: "icon",
                              file: _unref(closeIcon),
                              width: "14px",
                              height: "14px"
                            }, null, 8, ["file"])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_unref(TUITranslateService).t(`TUISearch.${tabItem.label}`)), 1))
              ], 10, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "result")
    ], 2)
  ], 2))
}
}

})