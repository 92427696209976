import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "list"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "plus"
}
const _hoisted_4 = { class: "tui-conversation-header-item-title" }
const _hoisted_5 = {
  key: 1,
  class: "tui-conversation-header-children list"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "tui-conversation-header-item-title" }

import { computed, ref, onMounted } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import Server, { IMenuItem } from './server';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {

const showChildren = ref<Array<IMenuItem>>([]);
const convHeaderRef = ref<HTMLElement | undefined>();

const menuList = computed(() => {
  return Server.getInstance().getMenu();
});

onMounted(() => {
  showChildren.value = [];
});

const handleMenu = (item: IMenuItem) => {
  const { data: { children }, listener = { onClicked: () => {} } } = item;
  if (children) {
    showChildren.value = showChildren.value.length > 0 ? [] : children;
  } else {
    listener.onClicked(item);
    closeChildren();
  }
};

const closeChildren = () => {
  showChildren.value = [];
};

__expose({
  closeChildren,
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: _unref(convHeaderRef),
    class: "tui-conversation-header"
  }, [
    (_unref(menuList).length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuList), (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "list-item"
            }, [
              _createElementVNode("main", {
                class: "tui-conversation-header-item",
                onClick: _withModifiers(($event: any) => (handleMenu(item)), ["stop"])
              }, [
                (item.icon && !item.data.children)
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      class: "tui-conversation-header-item-icon",
                      file: item.icon
                    }, null, 8, ["file"]))
                  : (_openBlock(), _createElementBlock("i", _hoisted_3)),
                _createElementVNode("h1", _hoisted_4, _toDisplayString(item.text), 1)
              ], 8, _hoisted_2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_unref(showChildren).length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(showChildren), (childrenItem, childrenIndex) => {
            return (_openBlock(), _createElementBlock("li", {
              key: childrenIndex,
              class: "list-item"
            }, [
              _createElementVNode("main", {
                class: "tui-conversation-header-item",
                onClick: ($event: any) => (handleMenu(childrenItem))
              }, [
                (childrenItem.icon)
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      class: "tui-conversation-header-item-icon",
                      file: childrenItem.icon
                    }, null, 8, ["file"]))
                  : _createCommentVNode("", true),
                _createElementVNode("h1", _hoisted_7, _toDisplayString(childrenItem.text), 1)
              ], 8, _hoisted_6)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})