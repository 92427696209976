import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "revoke" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

import { watchEffect, ref } from '../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';

export default /*@__PURE__*/_defineComponent({
  __name: 'message-revoked',
  props: {
  isEdit: {
    type: Boolean,
    default: () => false,
  },
  messageItem: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ['messageEdit'],
  setup(__props, { emit: __emit }) {

const props = __props;

const message = ref<IMessageModel>();
const isEditMsg = ref(false);
const emits = __emit;

watchEffect(() => {
  message.value = props.messageItem;
  isEditMsg.value = props.isEdit;
});
const messageEdit = () => {
  emits('messageEdit');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(message).flow === 'in')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(message).nick || _unref(message).from), 1))
      : (_unref(message).from === _unref(message).revoker)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t("TUIChat.您")), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(message).revoker), 1)),
    _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t("TUIChat.撤回了一条消息")), 1),
    (_unref(message).flow === 'out' && _unref(isEditMsg))
      ? (_openBlock(), _createElementBlock("span", {
          key: 3,
          class: "edit",
          onClick: messageEdit
        }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.重新编辑")), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})