import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "group-box" }
const _hoisted_2 = { class: "group-box-header" }
const _hoisted_3 = { class: "group-box-header-title" }
const _hoisted_4 = {
  key: 0,
  class: "group-list"
}
const _hoisted_5 = { class: "group-list-item" }
const _hoisted_6 = { class: "group-list-item-label" }
const _hoisted_7 = { class: "group-list-item-label" }
const _hoisted_8 = ["onUpdate:modelValue", "placeholder"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "group-list-introduction" }
const _hoisted_12 = { class: "group-list-item" }
const _hoisted_13 = { class: "group-list-item-label" }
const _hoisted_14 = { class: "content" }
const _hoisted_15 = {
  key: 0,
  class: "group-h5-list-item-introduction"
}
const _hoisted_16 = { class: "introduction-name" }
const _hoisted_17 = { class: "introduction-detail" }
const _hoisted_18 = ["href"]
const _hoisted_19 = {
  key: 1,
  class: "group-list group-list-edit"
}
const _hoisted_20 = ["placeholder"]
const _hoisted_21 = { class: "group-profile-footer" }
const _hoisted_22 = ["disabled"]

import TUIChatEngine, {
  TUITranslateService,
  TUIGroupService,
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import { computed, reactive, watchEffect } from '../../../adapter-vue';
import documentLink from '../../../utils/documentLink';
import { isPC } from '../../../utils/env';
import Icon from '../../common/Icon.vue';
import backIcon from '../../../assets/icon/back.svg';
import closeIcon from '../../../assets/icon/icon-close.svg';
import rightIcon from '../../../assets/icon/right-icon.svg';
import GroupIntroduction from './group-introduction/index.vue';
import { groupIntroConfig, findGroupIntroConfig } from './group-introduction/config';
import Dialog from '../../common/Dialog/index.vue';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import Avatar from '../../common/Avatar/index.vue';
import Server from '../server';
import { IUserProfile } from '../../../interface';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const TUIGroupServer = Server.getInstance();
const TUIConstants = TUIGroupServer.constants;

const groupInfo = reactive<any>({
  profile: {
    groupID: '',
    name: '',
    type: groupIntroConfig[0].type,
    avatar: groupIntroConfig[0].icon,
    introduction: '',
    notification: '',
    // joinOption: '',
    memberList: [],
    isSupportTopic: false,
  },
  groupConfig: {
    title: '',
    value: '',
    key: '',
    type: '',
    placeholder: '',
  },
  isEdit: false,
});

watchEffect(() => {
  const params = TUIGroupServer.getOnCallParams(TUIConstants.TUIGroup.SERVICE.METHOD.CREATE_GROUP);
  groupInfo.profile.memberList = params.memberList;
  groupInfo.groupConfig.title = params.title;
});

const groupTypeDetail = computed(() => {
  return findGroupIntroConfig(groupInfo.profile.type);
});

const headerTitle = computed(() => {
  let name = '添加群聊';
  if (groupInfo.isEdit) {
    name = groupInfo.groupConfig.title;
  }
  return TUITranslateService.t(`TUIGroup.${name}`);
});

const createInfo = computed(() => {
  const groupNameInput = {
    name: TUITranslateService.t('TUIGroup.群名称'),
    key: 'name',
    placeholder: TUITranslateService.t('TUIGroup.请输入群名称'),
  };
  const groupIDInput = {
    name: `${TUITranslateService.t('TUIGroup.群ID')}(${TUITranslateService.t('TUIGroup.选填')})`,
    key: 'groupID',
    placeholder: TUITranslateService.t('TUIGroup.请输入群ID'),
  };
  return groupInfo.profile.type === TUIChatEngine.TYPES.GRP_COMMUNITY
    ? [groupNameInput]
    : [groupNameInput, groupIDInput];
});

const submitDisabledStatus = computed(() => {
  return groupInfo.profile.name === '' && !groupInfo.isEdit;
});

const selected = (type: any) => {
  if (groupInfo.profile.type !== type) {
    groupInfo.profile.type = type;
    groupInfo.profile.avatar = findGroupIntroConfig(type).icon;
    if (groupInfo.isEdit) {
      groupInfo.groupConfig.value = type;
    }
  }
};

const createGroup = async (options: any) => {
  try {
    options.memberList = options.memberList.map((item: IUserProfile) => {
      return { userID: item.userID };
    });
    if (options.type === TUIChatEngine.TYPES.GRP_COMMUNITY) {
      delete options.groupID;
    }
    const res = await TUIGroupService.createGroup(options);
    const { type } = res.data.group;
    if (type === TUIChatEngine.TYPES.GRP_AVCHATROOM) {
      await TUIGroupService.joinGroup({
        groupID: res.data.group.groupID,
        applyMessage: '',
      });
    }
    handleCompleteCreate(res.data.group);
    Toast({
      message: TUITranslateService.t('TUIGroup.群组创建成功'),
      type: TOAST_TYPE.SUCCESS,
    });
  } catch (err: any) {
    Toast({
      message: err.message,
      type: TOAST_TYPE.ERROR,
    });
  }
};

const submit = () => {
  const { profile } = groupInfo;
  if (groupInfo.isEdit) {
    groupInfo.profile[groupInfo.groupConfig.key] = groupInfo.groupConfig.value;
    return groupInfo.isEdit = !groupInfo.isEdit;
  } else {
    createGroup(profile);
  }
};

const closeCreated = () => {
  if (groupInfo.isEdit) {
    return groupInfo.isEdit = !groupInfo.isEdit;
  }
  handleCompleteCreate(null);
};

const edit = (label: string) => {
  groupInfo.isEdit = !groupInfo.isEdit;
  groupInfo.groupConfig.key = label;
  groupInfo.groupConfig.value = (groupInfo.profile as any)[label];
  switch (label) {
    case 'name':
      groupInfo.groupConfig.title = '设置群名称';
      groupInfo.groupConfig.placeholder = '请输入群名称';
      groupInfo.groupConfig.type = 'input';
      break;
    case 'groupID':
      groupInfo.groupConfig.title = '设置群ID';
      groupInfo.groupConfig.placeholder = '请输入群ID';
      groupInfo.groupConfig.type = 'input';
      break;
    case 'type':
      groupInfo.groupConfig.title = '选择群类型';
      groupInfo.groupConfig.type = 'select';
      break;
  }
};

const handleCompleteCreate = (group: any) => {
  TUIStore.update(StoreName.GRP, 'isShowCreateComponent', false);
  const callback = TUIGroupServer.getOnCallCallback(TUIConstants.TUIGroup.SERVICE.METHOD.CREATE_GROUP);
  callback && callback(group);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Dialog, {
    show: true,
    isH5: !_unref(isPC),
    isHeaderShow: false,
    isFooterShow: false,
    background: false,
    "onUpdate:show": closeCreated
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["group", [!_unref(isPC) ? 'group-h5' : '']])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", _hoisted_2, [
            _createVNode(Icon, {
              file: _unref(isPC) ? _unref(closeIcon) : _unref(backIcon),
              class: "icon-close",
              size: "16px",
              onOnClick: closeCreated
            }, null, 8, ["file"]),
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref(headerTitle)), 1)
          ]),
          (!_unref(groupInfo).isEdit)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                _createElementVNode("li", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_unref(TUITranslateService).t('TUIGroup.群头像')), 1),
                  _createVNode(Avatar, {
                    url: _unref(groupInfo).profile.avatar
                  }, null, 8, ["url"])
                ]),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(createInfo), (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "group-list-item"
                    }, [
                      _createElementVNode("label", _hoisted_7, _toDisplayString(item.name), 1),
                      (_unref(isPC))
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            "onUpdate:modelValue": ($event: any) => ((_unref(groupInfo).profile[item.key]) = $event),
                            type: "text",
                            placeholder: item.placeholder
                          }, null, 8, _hoisted_8)), [
                            [_vModelText, _unref(groupInfo).profile[item.key]]
                          ])
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "group-h5-list-item-content",
                            onClick: ($event: any) => (edit(item.key))
                          }, [
                            _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(groupInfo).profile[item.key]), 1),
                            _createVNode(Icon, { file: _unref(rightIcon) }, null, 8, ["file"])
                          ], 8, _hoisted_9))
                    ]))
                  }), 128)),
                  _createElementVNode("li", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("label", _hoisted_13, _toDisplayString(_unref(TUITranslateService).t('TUIGroup.群类型')), 1),
                      (_unref(isPC))
                        ? (_openBlock(), _createBlock(GroupIntroduction, {
                            key: 0,
                            groupType: _unref(groupInfo).profile.type,
                            onSelectType: selected
                          }, null, 8, ["groupType"]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: "group-h5-list-item-content",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (edit('type')))
                          }, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(groupTypeDetail).label), 1),
                            _createVNode(Icon, { file: _unref(rightIcon) }, null, 8, ["file"])
                          ]))
                    ]),
                    (!_unref(isPC))
                      ? (_openBlock(), _createElementBlock("article", _hoisted_15, [
                          _createElementVNode("label", _hoisted_16, _toDisplayString(_unref(groupTypeDetail).label) + "：", 1),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(groupTypeDetail).detail), 1),
                          _createElementVNode("a", {
                            href: _unref(documentLink).product.url,
                            target: "view_window"
                          }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${_unref(groupTypeDetail).src}`)), 9, _hoisted_18)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                (_unref(groupInfo).groupConfig.type === 'input')
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(groupInfo).groupConfig.value) = $event)),
                      class: "group-name-input",
                      type: "text",
                      placeholder: _unref(TUITranslateService).t(`TUIGroup.${_unref(groupInfo).groupConfig.placeholder}`)
                    }, null, 8, _hoisted_20)), [
                      [_vModelText, _unref(groupInfo).groupConfig.value]
                    ])
                  : (_openBlock(), _createBlock(GroupIntroduction, {
                      key: 1,
                      class: "group-introduction-list",
                      groupType: _unref(groupInfo).groupConfig.value,
                      onSelectType: selected
                    }, null, 8, ["groupType"]))
              ])),
          _createElementVNode("footer", _hoisted_21, [
            (_unref(isPC) && !_unref(groupInfo).isEdit)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-default",
                  onClick: closeCreated
                }, _toDisplayString(_unref(TUITranslateService).t('TUIGroup.取消')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              class: "btn-submit",
              disabled: _unref(submitDisabledStatus),
              onClick: submit
            }, _toDisplayString(_unref(TUITranslateService).t('TUIGroup.确认')), 9, _hoisted_22)
          ])
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["isH5"]))
}
}

})