import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "header"
}
const _hoisted_3 = {
  key: 0,
  class: "header-title"
}
const _hoisted_4 = {
  key: 1,
  class: "footer"
}

import { ref, watch, nextTick } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { outsideClick } from '@tencentcloud/universal-api';
import { isPC, isH5, isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  // Whether to display the bottom pop-up dialog box
  show: {
    type: Boolean,
    default: false,
  },
  // Whether a mask layer is required, the default is true
  modal: {
    type: Boolean,
    default: true,
  },
  // Popup box content area height (excluding mask), default is fit-content
  height: {
    type: String,
    default: 'fit-content',
  },
  // Whether the pop-up dialog box can be closed by clicking outside, the default is true
  // uniapp only supports closing the pop-up dialog box by clicking the mask
  closeByClickOutside: {
    type: Boolean,
    default: true,
  },
  // The rounded angle of the top border corners is 0px by default, i.e. right angle by default
  borderRadius: {
    type: String,
    default: '0px',
  },
  title: {
    type: String,
    default: '',
  },
  // Whether to display the top close button, not displayed by default
  showHeaderCloseButton: {
    type: Boolean,
    default: false,
  },
  // Whether to display the submit button at the bottom, not displayed by default
  showFooterSubmitButton: {
    type: Boolean,
    default: false,
  },
  // Bottom submit button text, only valid when showFooterSubmitButton is true
  submitButtonContent: {
    type: String,
    default: () => TUITranslateService.t('确定'),
  },
},
  emits: ['onOpen', 'onClose', 'onSubmit'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;
const dialogRef = ref();

watch(
  () => props.show,
  (newVal: boolean, oldVal: boolean) => {
    if (newVal === oldVal) {
      return;
    }
    switch (newVal) {
      case true:
        emits('onOpen', dialogRef);
        nextTick(() => {
          // Effective under web h5
          if (isH5 && !isUniFrameWork) {
            if (props.closeByClickOutside) {
              outsideClick.listen({
                domRefs: dialogRef.value,
                handler: closeBottomPopup,
              });
            }
          }
        });
        break;
      case false:
        emits('onClose', dialogRef);
        break;
    }
  },
);

const closeBottomPopup = () => {
  if (isUniFrameWork || isH5) {
    emits('onClose', dialogRef);
  }
};

const submit = () => {
  emits('onSubmit');
  closeBottomPopup();
};

return (_ctx: any,_cache: any) => {
  return (props.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_unref(isPC))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
        'bottom-popup',
        !_unref(isPC) && 'bottom-popup-h5',
        !_unref(isPC) && props.modal && 'bottom-popup-modal',
        _unref(isUniFrameWork) && 'bottom-popup-uni',
      ]),
              onClick: closeBottomPopup
            }, [
              _createElementVNode("div", {
                ref_key: "dialogRef",
                ref: dialogRef,
                class: _normalizeClass(['bottom-popup-main', !_unref(isPC) && 'bottom-popup-h5-main']),
                style: _normalizeStyle({
          height: props.height,
          borderTopLeftRadius: props.borderRadius,
          borderTopRightRadius: props.borderRadius,
        }),
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, [
                (__props.title || __props.showHeaderCloseButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (__props.title)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.title), 1))
                        : _createCommentVNode("", true),
                      (__props.showHeaderCloseButton)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "header-close",
                            onClick: closeBottomPopup
                          }, _toDisplayString(_unref(TUITranslateService).t("关闭")), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default"),
                (__props.showFooterSubmitButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("div", {
                        class: "footer-submit",
                        onClick: submit
                      }, _toDisplayString(__props.submitButtonContent), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 6)
            ], 2))
          : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ]))
    : _createCommentVNode("", true)
}
}

})