import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "network"
}
const _hoisted_2 = { class: "network-content" }

import TUIChatEngine, {
  TUIStore,
  StoreName,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import {
  ref,
} from '../../../adapter-vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const isNotNetwork = ref(false);

TUIStore.watch(StoreName.USER, {
  netStateChange: (value: string) => {
    isNotNetwork.value = (value === TUIChatEngine.TYPES.NET_STATE_DISCONNECTED);
  },
});

return (_ctx: any,_cache: any) => {
  return (_unref(isNotNetwork))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "icon icon-error" }, "!", -1)),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.网络异常，请您检查网络设置")), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})