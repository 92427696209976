import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tui-search-more-list"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "list-item-title" }

import { ref, watch, onMounted } from '../../../adapter-vue';
import { TUIStore, StoreName } from '@tencentcloud/chat-uikit-engine';
import TUICore, { ExtensionInfo, TUIConstants } from '@tencentcloud/tui-core';
import { outsideClick } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import searchMoreSVG from '../../../assets/icon/search-more.svg';
import { isPC, isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  searchType: {
    type: String,
    default: 'global', // "global" / "conversation"
    validator(value: string) {
      return ['global', 'conversation'].includes(value);
    },
  },
},
  setup(__props) {

const props = __props;

const searchMoreRef = ref<HTMLElement | null>();
const isListShow = ref<boolean>(false);
const toggleMore = () => {
  isListShow.value = !isListShow.value;
  if (!isUniFrameWork && isListShow.value) {
    outsideClick.listen({
      domRefs: searchMoreRef.value,
      handler: closeSearchMore,
    });
  }
};
const extensionList = ref<ExtensionInfo[]>([]);

const handleMenu = (item: ExtensionInfo) => {
  const { listener = { onClicked: () => { } } } = item;
  listener?.onClicked?.(item);
  toggleMore();
};

const closeSearchMore = () => {
  isListShow.value = false;
};

onMounted(() => {
  // extensions
  extensionList.value = [
    ...TUICore.getExtensionList(TUIConstants.TUISearch.EXTENSION.SEARCH_MORE.EXT_ID),
  ];
  // hide conversation header
  TUICore.callService({
    serviceName: TUIConstants.TUIConversation.SERVICE.NAME,
    method: TUIConstants.TUIConversation.SERVICE.METHOD.HIDE_CONVERSATION_HEADER,
    params: {},
  });
});

watch(
  () => isListShow.value,
  () => {
    if (isListShow.value) {
      TUIStore.update(StoreName.SEARCH, 'currentSearchingStatus', {
        isSearching: false,
        searchType: props.searchType,
      });
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "searchMoreRef",
    ref: searchMoreRef,
    class: _normalizeClass(['tui-search-more', !_unref(isPC) && 'tui-search-more-h5'])
  }, [
    _createElementVNode("div", {
      class: "more",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleMore()))
    }, [
      _createVNode(Icon, {
        class: "more-icon",
        file: _unref(searchMoreSVG),
        width: _unref(isPC) ? '28px' : '34px',
        height: _unref(isPC) ? '28px' : '34px'
      }, null, 8, ["file", "width", "height"])
    ]),
    (_unref(isListShow))
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(extensionList), (extension, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "list-item",
              onClick: ($event: any) => (handleMenu(extension))
            }, [
              (extension.icon)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    class: "list-item-icon",
                    file: extension.icon
                  }, null, 8, ["file"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, _toDisplayString(extension.text), 1)
            ], 8, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})