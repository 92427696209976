import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-file-left'])
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-file-main'])
}
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-file-main-name'])
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(['message-abstract-file-main-size'])
}

import { ref, computed, withDefaults } from '../../../../../adapter-vue';
import { IHighlightContent } from '../../../type';
interface IProps {
  contentText: Array<IHighlightContent>;
  messageContent: Record<string, unknown> | undefined;
  displayType: 'bubble' | 'info';
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-abstract-file',
  props: {
    contentText: { default: () => ([]) as Array<IHighlightContent> },
    messageContent: { default: () => ({}) as Record<string, unknown> },
    displayType: { default: 'bubble' }
  },
  setup(__props: any) {

const props = __props;

const contentText = ref<Array<{ text: string; isHighlight: boolean }>>(props.contentText);
const typeIcon = computed(() => {
  const fileUrl = props?.messageContent?.url as string;
  const index = fileUrl?.lastIndexOf('.');
  const type = fileUrl?.substring(index + 1);
  return handleFileIconForShow(type);
});
const fileSize = computed(() => props?.messageContent?.size);
const handleFileIconForShow = (type: string) => {
  const urlBase = 'https://web.sdk.qcloud.com/component/TUIKit/assets/file-';
  const fileTypes = [
    'image',
    'pdf',
    'text',
    'ppt',
    'presentation',
    'sheet',
    'zip',
    'word',
    'video',
    'unknown',
  ];
  let url = '';
  let iconType = '';
  fileTypes?.forEach((typeName: string) => {
    if (type?.includes(typeName)) {
      url = urlBase + typeName + '.svg';
      iconType = typeName;
    }
  });
  return {
    iconSrc: url ? url : urlBase + 'unknown.svg',
    iconType: iconType ? iconType : 'unknown',
  };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message-abstract-file', `message-abstract-file-${_ctx.displayType}`])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: _normalizeClass(['message-abstract-file-left-icon']),
        src: _unref(typeIcon).iconSrc
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contentText), (contentItem, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: _normalizeClass([(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal'])
          }, _toDisplayString(contentItem.text), 3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(fileSize)), 1)
    ])
  ], 2))
}
}

})