import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "chat" }

import { ref, onMounted, onUnmounted, computed } from '../../adapter-vue';
import TUIChatEngine, {
  TUITranslateService,
  TUIConversationService,
  TUIStore,
  StoreName,
  IMessageModel,
  IConversationModel,
} from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants, ExtensionInfo } from '@tencentcloud/tui-core';
import ChatHeader from './chat-header/index.vue';
import MessageList from './message-list/index.vue';
import MessageInput from './message-input/index.vue';
import MultipleSelectPanel from './mulitple-select-panel/index.vue';
import Forward from './forward/index.vue';
import MessageInputToolbar from './message-input-toolbar/index.vue';
import { isPC, isWeChat, isUniFrameWork, isMobile, isApp } from '../../utils/env';
import { ToolbarDisplayType } from '../../interface';
import TUIChatConfig from './config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['closeChat'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const groupID = ref(undefined);
const isGroup = ref(false);
const isNotInGroup = ref(false);
const notInGroupReason = ref<number>();
const currentConversationID = ref();
const isMultipleSelectMode = ref(false);
const inputToolbarDisplayType = ref<ToolbarDisplayType>('none');
const messageInputRef = ref();
const messageListRef = ref<InstanceType<typeof MessageList>>();
const headerExtensionList = ref<ExtensionInfo[]>([]);
const featureConfig = TUIChatConfig.getFeatureConfig();

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
    currentConversation: onCurrentConversationUpdate,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdate,
    currentConversation: onCurrentConversationUpdate,
  });
  reset();
});

const isInputToolbarShow = computed<boolean>(() => {
  return isUniFrameWork ? inputToolbarDisplayType.value !== 'none' : true;
});

const leaveGroupReasonText = computed<string>(() => {
  let text = '';
  switch (notInGroupReason.value) {
    case 4:
      text = TUITranslateService.t('TUIChat.您已被管理员移出群聊');
      break;
    case 5:
      text = TUITranslateService.t('TUIChat.该群聊已被解散');
      break;
    case 8:
      text = TUITranslateService.t('TUIChat.您已退出该群聊');
      break;
    default:
      text = TUITranslateService.t('TUIChat.您已退出该群聊');
      break;
  }
  return text;
});

const reset = () => {
  TUIConversationService.switchConversation('');
};

const closeChat = (conversationID: string) => {
  emits('closeChat', conversationID);
  reset();
};

const insertEmoji = (emojiObj: object) => {
  messageInputRef.value?.insertEmoji(emojiObj);
};

const handleEditor = (message: IMessageModel, type: string) => {
  if (!message || !type) return;
  switch (type) {
    case 'reference':
      // todo
      break;
    case 'reply':
      // todo
      break;
    case 'reedit':
      if (message?.payload?.text) {
        messageInputRef?.value?.reEdit(message?.payload?.text);
      }
      break;
    default:
      break;
  }
};

const handleGroup = () => {
  headerExtensionList.value[0].listener.onClicked({ groupID: groupID.value });
};

function changeToolbarDisplayType(type: ToolbarDisplayType) {
  inputToolbarDisplayType.value = inputToolbarDisplayType.value === type ? 'none' : type;
  if (inputToolbarDisplayType.value !== 'none' && isUniFrameWork) {
    uni.$emit('scroll-to-bottom');
  }
}

function scrollToLatestMessage() {
  messageListRef.value?.scrollToLatestMessage();
}

function toggleMultipleSelectMode(visible?: boolean) {
  isMultipleSelectMode.value = visible === undefined ? !isMultipleSelectMode.value : visible;
}

function mergeForwardMessage() {
  messageListRef.value?.mergeForwardMessage();
}

function oneByOneForwardMessage() {
  messageListRef.value?.oneByOneForwardMessage();
}

function onCurrentConversationUpdate(conversation: IConversationModel) {
  if (conversation?.operationType > 0) {
    headerExtensionList.value = [];
    isNotInGroup.value = true;
    /**
     * 4 - be removed from the group
     * 5 - group is dismissed
     * 8 - quit group
     */
    notInGroupReason.value = conversation?.operationType;
  } else {
    isNotInGroup.value = false;
    notInGroupReason.value = undefined;
  }
}

function onCurrentConversationIDUpdate(conversationID: string) {
  if (currentConversationID.value === conversationID) {
    return;
  }

  isGroup.value = false;
  let conversationType = TUIChatEngine.TYPES.CONV_C2C;
  if (conversationID.startsWith(TUIChatEngine.TYPES.CONV_GROUP)) {
    conversationType = TUIChatEngine.TYPES.CONV_GROUP;
    isGroup.value = true;
    groupID.value = conversationID.replace(TUIChatEngine.TYPES.CONV_GROUP, '');
  }

  headerExtensionList.value = [];
  isMultipleSelectMode.value = false;
  // Initialize chatType
  TUIChatConfig.setChatType(conversationType);
  // While converstaion change success, notify callkit and roomkit、or other components.
  TUICore.notifyEvent(TUIConstants.TUIChat.EVENT.CHAT_STATE_CHANGED, TUIConstants.TUIChat.EVENT_SUB_KEY.CHAT_OPENED, { groupID: groupID.value });
  // The TUICustomerServicePlugin plugin determines if the current conversation is a customer service conversation, then sets chatType and activates the conversation.
  TUICore.callService({
    serviceName: TUIConstants.TUICustomerServicePlugin.SERVICE.NAME,
    method: TUIConstants.TUICustomerServicePlugin.SERVICE.METHOD.ACTIVE_CONVERSATION,
    params: { conversationID: conversationID },
  });
  // When open chat in room, close main chat ui and reset theme.
  if (TUIChatConfig.getChatType() === TUIConstants.TUIChat.TYPE.ROOM) {
    if (TUIChatConfig.getFeatureConfig(TUIConstants.TUIChat.FEATURE.InputVoice) === true) {
      TUIChatConfig.setTheme('light');
      currentConversationID.value = '';
      return;
    }
  }
  // Get chat header extensions
  if (TUIChatConfig.getChatType() === TUIConstants.TUIChat.TYPE.GROUP) {
    headerExtensionList.value = TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.CHAT_HEADER.EXT_ID);
  }
  TUIStore.update(StoreName.CUSTOM, 'activeConversation', conversationID);
  currentConversationID.value = conversationID;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['tui-chat', !_unref(isPC) && 'tui-chat-h5'])
    }, [
      (!_unref(currentConversationID))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(['tui-chat-default', !_unref(isPC) && 'tui-chat-h5-default'])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true),
      (_unref(currentConversationID))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['tui-chat', !_unref(isPC) && 'tui-chat-h5'])
          }, [
            _createVNode(ChatHeader, {
              class: _normalizeClass([
            'tui-chat-header',
            !_unref(isPC) && 'tui-chat-H5-header',
            _unref(isUniFrameWork) && 'tui-chat-uniapp-header',
          ]),
              isGroup: _unref(isGroup),
              headerExtensionList: _unref(headerExtensionList),
              onCloseChat: closeChat,
              onOpenGroupManagement: handleGroup
            }, null, 8, ["class", "isGroup", "headerExtensionList"]),
            _createVNode(Forward, { onToggleMultipleSelectMode: toggleMultipleSelectMode }),
            _createVNode(MessageList, {
              ref_key: "messageListRef",
              ref: messageListRef,
              class: _normalizeClass(['tui-chat-message-list', !_unref(isPC) && 'tui-chat-h5-message-list']),
              isGroup: _unref(isGroup),
              groupID: _unref(groupID),
              isNotInGroup: _unref(isNotInGroup),
              isMultipleSelectMode: _unref(isMultipleSelectMode),
              onHandleEditor: handleEditor,
              onCloseInputToolBar: _cache[0] || (_cache[0] = () => changeToolbarDisplayType('none')),
              onToggleMultipleSelectMode: toggleMultipleSelectMode
            }, null, 8, ["class", "isGroup", "groupID", "isNotInGroup", "isMultipleSelectMode"]),
            (_unref(isNotInGroup))
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass({
            'tui-chat-leave-group': true,
            'tui-chat-leave-group-mobile': _unref(isMobile),
          })
                }, _toDisplayString(_unref(leaveGroupReasonText)), 3))
              : (_unref(isMultipleSelectMode))
                ? (_openBlock(), _createBlock(MultipleSelectPanel, {
                    key: 1,
                    onOneByOneForwardMessage: oneByOneForwardMessage,
                    onMergeForwardMessage: mergeForwardMessage,
                    onToggleMultipleSelectMode: toggleMultipleSelectMode
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_unref(isInputToolbarShow))
                      ? (_openBlock(), _createBlock(MessageInputToolbar, {
                          key: 0,
                          class: _normalizeClass([
              'tui-chat-message-input-toolbar',
              !_unref(isPC) && 'tui-chat-h5-message-input-toolbar',
              _unref(isUniFrameWork) && 'tui-chat-uni-message-input-toolbar'
            ]),
                          displayType: _unref(inputToolbarDisplayType),
                          onInsertEmoji: insertEmoji,
                          onChangeToolbarDisplayType: changeToolbarDisplayType,
                          onScrollToLatestMessage: scrollToLatestMessage
                        }, null, 8, ["class", "displayType"]))
                      : _createCommentVNode("", true),
                    _createVNode(MessageInput, {
                      ref_key: "messageInputRef",
                      ref: messageInputRef,
                      class: _normalizeClass([
              'tui-chat-message-input',
              !_unref(isPC) && 'tui-chat-h5-message-input',
              _unref(isUniFrameWork) && 'tui-chat-uni-message-input',
              _unref(isWeChat) && 'tui-chat-wx-message-input',
            ]),
                      enableAt: _unref(featureConfig).InputMention,
                      isMuted: false,
                      muteText: _unref(TUITranslateService).t('TUIChat.您已被管理员禁言'),
                      placeholder: _unref(TUITranslateService).t('TUIChat.请输入消息'),
                      inputToolbarDisplayType: _unref(inputToolbarDisplayType),
                      onChangeToolbarDisplayType: changeToolbarDisplayType
                    }, null, 8, ["class", "enableAt", "muteText", "placeholder", "inputToolbarDisplayType"])
                  ], 64))
          ], 2))
        : _createCommentVNode("", true),
      (!_unref(isNotInGroup) && !_unref(isApp) && _unref(isUniFrameWork) && _unref(isGroup) && _unref(headerExtensionList).length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "group-profile",
            onClick: handleGroup
          }, _toDisplayString(_unref(headerExtensionList)[0].text), 1))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})