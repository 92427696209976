import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "tui-contact-list-item-header-left" }
const _hoisted_3 = { class: "tui-contact-list-item-header-right" }
const _hoisted_4 = {
  key: 0,
  class: "tui-contact-list-item-header-right-unread"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "tui-contact-list"
}
const _hoisted_7 = {
  key: 0,
  class: "tui-contact-search-list"
}
const _hoisted_8 = { class: "tui-contact-search-list-title" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "tui-contact-search-list-default"
}

import {
  TUITranslateService,
  TUIStore,
  StoreName,
  IGroupModel,
  TUIFriendService,
  Friend,
  FriendApplication,
  TUIUserService,
} from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants } from '@tencentcloud/tui-core';
import { ref, computed, onMounted, onUnmounted, provide } from '../../../adapter-vue';
import Icon from '../../common/Icon.vue';
import downSVG from '../../../assets/icon/down-icon.svg';
import rightSVG from '../../../assets/icon/right-icon.svg';
import {
  IContactList,
  IContactSearchResult,
  IBlackListUserItem,
  IUserStatus,
  IUserStatusMap,
  IContactInfoType,
} from '../../../interface';
import ContactListItem from './contact-list-item/index.vue';
import { deepCopy } from '../../TUIChat/utils/utils';
import { isPC } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const currentContactListKey = ref<keyof IContactList>('');
const currentContactInfo = ref<IContactInfoType>({} as IContactInfoType);
const contactListMap = ref<IContactList>({
  friendApplicationList: {
    key: 'friendApplicationList',
    title: '新的联系人',
    list: [] as FriendApplication[],
    unreadCount: 0,
  },
  blackList: {
    key: 'blackList',
    title: '黑名单',
    list: [] as IBlackListUserItem[],
  },
  groupList: {
    key: 'groupList',
    title: '我的群聊',
    list: [] as IGroupModel[],
  },
  friendList: {
    key: 'friendList',
    title: '我的好友',
    list: [] as Friend[],
  },
});
const contactSearchingStatus = ref<boolean>(false);
const contactSearchResult = ref<IContactSearchResult>();
const displayOnlineStatus = ref<boolean>(false);
const userOnlineStatusMap = ref<IUserStatusMap>();

const isContactSearchNoResult = computed((): boolean => {
  return (
    !contactSearchResult?.value?.user?.list[0]
    && !contactSearchResult?.value?.group?.list[0]
  );
});

onMounted(() => {
  TUIStore.watch(StoreName.APP, {
    enabledCustomerServicePlugin: onCustomerServiceCommercialPluginUpdated,
  });

  TUIStore.watch(StoreName.GRP, {
    groupList: onGroupListUpdated,
  });

  TUIStore.watch(StoreName.USER, {
    userBlacklist: onUserBlacklistUpdated,
    displayOnlineStatus: onDisplayOnlineStatusUpdated,
    userStatusList: onUserStatusListUpdated,
  });

  TUIStore.watch(StoreName.FRIEND, {
    friendList: onFriendListUpdated,
    friendApplicationList: onFriendApplicationListUpdated,
    friendApplicationUnreadCount: onFriendApplicationUnreadCountUpdated,
  });

  TUIStore.watch(StoreName.CUSTOM, {
    currentContactSearchingStatus: onCurrentContactSearchingStatusUpdated,
    currentContactSearchResult: onCurrentContactSearchResultUpdated,
    currentContactListKey: onCurrentContactListKeyUpdated,
    currentContactInfo: onCurrentContactInfoUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.APP, {
    enabledCustomerServicePlugin: onCustomerServiceCommercialPluginUpdated,
  });

  TUIStore.unwatch(StoreName.GRP, {
    groupList: onGroupListUpdated,
  });

  TUIStore.unwatch(StoreName.USER, {
    userBlacklist: onUserBlacklistUpdated,
    displayOnlineStatus: onDisplayOnlineStatusUpdated,
    userStatusList: onUserStatusListUpdated,
  });

  TUIStore.unwatch(StoreName.FRIEND, {
    friendList: onFriendListUpdated,
    friendApplicationList: onFriendApplicationListUpdated,
    friendApplicationUnreadCount: onFriendApplicationUnreadCountUpdated,
  });

  TUIStore.unwatch(StoreName.CUSTOM, {
    currentContactSearchingStatus: onCurrentContactSearchingStatusUpdated,
    currentContactSearchResult: onCurrentContactSearchResultUpdated,
    currentContactListKey: onCurrentContactListKeyUpdated,
    currentContactInfo: onCurrentContactInfoUpdated,
  });
});

function toggleCurrentContactList(key: keyof IContactList) {
  if (currentContactListKey.value === key) {
    currentContactListKey.value = '';
    currentContactInfo.value = {} as IContactInfoType;
    TUIStore.update(StoreName.CUSTOM, 'currentContactListKey', '');
    TUIStore.update(StoreName.CUSTOM, 'currentContactInfo', {} as IContactInfoType);
  } else {
    currentContactListKey.value = key;
    TUIStore.update(StoreName.CUSTOM, 'currentContactListKey', key);
    if (key === 'friendApplicationList') {
      TUIFriendService.setFriendApplicationRead();
    }
  }
}

function selectItem(item: any) {
  currentContactInfo.value = item;
  // For a result in the search list, before viewing the contactInfo details,
  // it is necessary to update the data for the "already in the group list/already in the friend list" situation to obtain more detailed information
  if (contactSearchingStatus.value) {
    let targetListItem;
    if ((currentContactInfo.value as Friend)?.userID) {
      targetListItem = contactListMap.value?.friendList?.list?.find(
        (item: IContactInfoType) => (item as Friend)?.userID === (currentContactInfo.value as Friend)?.userID,
      );
    } else if ((currentContactInfo.value as IGroupModel)?.groupID) {
      targetListItem = contactListMap.value?.groupList?.list?.find(
        (item: IContactInfoType) => (item as IGroupModel)?.groupID === (currentContactInfo.value as IGroupModel)?.groupID,
      );
    }
    if (targetListItem) {
      currentContactInfo.value = targetListItem;
    }
  }
  TUIStore.update(StoreName.CUSTOM, 'currentContactInfo', currentContactInfo.value);
}

function onDisplayOnlineStatusUpdated(status: boolean) {
  displayOnlineStatus.value = status;
}

function onUserStatusListUpdated(list: Map<string, IUserStatus>) {
  if (list?.size > 0) {
    userOnlineStatusMap.value = Object.fromEntries(list?.entries());
  }
}

function onCustomerServiceCommercialPluginUpdated(isEnabled: boolean) {
  if (!isEnabled) {
    return;
  }

  // After the customer purchases the customer service plug-in,
  // the engine updates the enabledCustomerServicePlugin to true through the commercial capability bit.
  const contactListExtensionID = TUIConstants.TUIContact.EXTENSION.CONTACT_LIST.EXT_ID;
  const tuiContactExtensionList = TUICore.getExtensionList(contactListExtensionID);

  const customerData = tuiContactExtensionList.find((extension: any) => {
    const { name, accountList = [] } = extension.data || {};
    return name === 'customer' && accountList.length > 0;
  });

  if (customerData) {
    const { data, text } = customerData;
    const { accountList } = (data || {}) as { accountList: string[] };

    TUIUserService.getUserProfile({ userIDList: accountList })
      .then((res) => {
        if (res.data.length > 0) {
          const customerList = {
            title: text,
            list: res.data.map((item: any, index: number) => {
              return {
                ...item,
                renderKey: generateRenderKey('customerList', item, index),
                infoKeyList: [],
                btnKeyList: ['enterC2CConversation'],
              };
            }),
            key: 'customerList',
          };
          contactListMap.value = { ...contactListMap.value, customerList };
        }
      })
      .catch(() => { });
  }
}

function onGroupListUpdated(groupList: IGroupModel[]) {
  updateContactListMap('groupList', groupList);
}

function onUserBlacklistUpdated(userBlacklist: IBlackListUserItem[]) {
  updateContactListMap('blackList', userBlacklist);
}

function onFriendApplicationUnreadCountUpdated(friendApplicationUnreadCount: number) {
  contactListMap.value.friendApplicationList.unreadCount = friendApplicationUnreadCount;
}

function onFriendListUpdated(friendList: Friend[]) {
  updateContactListMap('friendList', friendList);
}

function onFriendApplicationListUpdated(friendApplicationList: FriendApplication[]) {
  updateContactListMap('friendApplicationList', friendApplicationList);
}

function updateContactListMap(key: keyof IContactList, list: IContactInfoType[]) {
  contactListMap.value[key].list = list;
  contactListMap.value[key].list.map((item: IContactInfoType, index: number) => item.renderKey = generateRenderKey(key, item, index));
  updateCurrentContactInfoFromList(contactListMap.value[key].list, key);
}

function updateCurrentContactInfoFromList(list: IContactInfoType[], type: keyof IContactList) {
  if (
    !(currentContactInfo.value as Friend)?.userID
    && !(currentContactInfo.value as IGroupModel)?.groupID
  ) {
    return;
  }
  if (type === currentContactListKey.value || contactSearchingStatus.value) {
    currentContactInfo.value = list?.find(
      (item: any) =>
        (item?.groupID && item?.groupID === (currentContactInfo.value as IGroupModel)?.groupID) || (item?.userID && item?.userID === (currentContactInfo.value as Friend)?.userID),
    ) || {} as IContactInfoType;
    TUIStore.update(StoreName.CUSTOM, 'currentContactInfo', currentContactInfo.value);
  }
}

function generateRenderKey(contactListMapKey: keyof IContactList, contactInfo: IContactInfoType, index: number) {
  return `${contactListMapKey}-${(contactInfo as Friend).userID || (contactInfo as IGroupModel).groupID || ('index' + index)}`;
}

function onCurrentContactSearchResultUpdated(searchResult: IContactSearchResult) {
  contactSearchResult.value = searchResult;
}

function onCurrentContactSearchingStatusUpdated(searchingStatus: boolean) {
  contactSearchingStatus.value = searchingStatus;
  TUIStore.update(StoreName.CUSTOM, 'currentContactInfo', {} as IContactInfoType);
  TUIStore.update(StoreName.CUSTOM, 'currentContactListKey', '');
}

function onCurrentContactInfoUpdated(contactInfo: IContactInfoType) {
  currentContactInfo.value = contactInfo;
}

function onCurrentContactListKeyUpdated(contactListKey: string) {
  currentContactListKey.value = contactListKey;
}

provide('userOnlineStatusMap', userOnlineStatusMap);

return (_ctx: any,_cache: any) => {
  return (!_unref(contactSearchingStatus))
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(['tui-contact-list', !_unref(isPC) && 'tui-contact-list-h5'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactListMap), (contactListObj, key) => {
          return (_openBlock(), _createElementBlock("li", {
            key: key,
            class: "tui-contact-list-item"
          }, [
            _createElementVNode("header", {
              class: "tui-contact-list-item-header",
              onClick: ($event: any) => (toggleCurrentContactList(key))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(Icon, {
                  file: _unref(currentContactListKey) === key ? _unref(downSVG) : _unref(rightSVG),
                  width: "16px",
                  height: "16px"
                }, null, 8, ["file"]),
                _createElementVNode("div", null, _toDisplayString(_unref(TUITranslateService).t(`TUIContact.${contactListObj.title}`)), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                (contactListObj.unreadCount)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(contactListObj.unreadCount), 1))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_1),
            _createElementVNode("ul", {
              class: _normalizeClass(['tui-contact-list-item-main', _unref(currentContactListKey) === key ? '' : 'hidden'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contactListObj.list, (contactListItem) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: contactListItem.renderKey,
                  class: _normalizeClass(["tui-contact-list-item-main-item", ['selected']]),
                  onClick: ($event: any) => (selectItem(contactListItem))
                }, [
                  (_openBlock(), _createBlock(ContactListItem, {
                    key: contactListItem.renderKey,
                    item: _unref(deepCopy)(contactListItem),
                    displayOnlineStatus: _unref(displayOnlineStatus) && key === 'friendList'
                  }, null, 8, ["item", "displayOnlineStatus"]))
                ], 8, _hoisted_5))
              }), 128))
            ], 2)
          ]))
        }), 128))
      ], 2))
    : (_openBlock(), _createElementBlock("ul", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactSearchResult), (item, key) => {
          return (_openBlock(), _createElementBlock("li", {
            key: key,
            class: "tui-contact-list-item"
          }, [
            (item.list[0])
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(TUITranslateService).t(`TUIContact.${item.label}`)), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.list, (listItem, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["tui-contact-search-list-item", ['selected']]),
                      onClick: ($event: any) => (selectItem(listItem))
                    }, [
                      _createVNode(ContactListItem, {
                        item: listItem,
                        displayOnlineStatus: false
                      }, null, 8, ["item"])
                    ], 8, _hoisted_9))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_unref(isContactSearchNoResult))
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(TUITranslateService).t("TUIContact.无搜索结果")), 1))
          : _createCommentVNode("", true)
      ]))
}
}

})