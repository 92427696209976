import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "delDialog-title" }

import {
  ref,
  nextTick,
  onMounted,
  computed,
  getCurrentInstance,
} from '../../../adapter-vue';
import TUIChatEngine, {
  IConversationModel,
  TUIStore,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { CONV_OPERATION } from '../../../constant';
import { isPC, isUniFrameWork } from '../../../utils/env';
import Overlay from '../../common/Overlay/index.vue';
import Dialog from '../../common/Dialog/index.vue';

interface IProps {
  actionsMenuPosition: {
    top: number;
    left?: number;
    conversationHeight?: number;
  };
  selectedConversation: IConversationModel | undefined;
  selectedConversationDomRect: DOMRect | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    actionsMenuPosition: {},
    selectedConversation: {},
    selectedConversationDomRect: {}
  },
  emits: ['closeConversationActionMenu'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const thisInstance = getCurrentInstance()?.proxy || getCurrentInstance();
const actionsMenuDomRef = ref<HTMLElement | null>();
const isHiddenActionsMenu = ref(true);
const isShowDeleteConversationDialog = ref<boolean>(false);
const currentConversation = TUIStore.getConversationModel(
  props.selectedConversation?.conversationID || '',
);
const _actionsMenuPosition = ref<{
  top: number;
  left?: number;
  conversationHeight?: number;
}>(props.actionsMenuPosition);

onMounted(() => {
  checkExceedBounds();
});

const deleteConversationDialogTitle = computed(() => {
  return props.selectedConversation?.type === TUIChatEngine.TYPES.CONV_C2C
    ? 'TUIConversation.删除后，将清空该聊天的消息记录'
    : props.selectedConversation?.type === TUIChatEngine.TYPES.CONV_GROUP ? 'TUIConversation.删除后，将清空该群聊的消息记录' : '';
});
function checkExceedBounds() {
  // When the component is initially rendered, it executes and self-checks whether the boundary exceeds the screen, and handles it in nextTick.
  nextTick(() => {
    if (isUniFrameWork) {
      // check exceed bounds
      const query = TUIGlobal?.createSelectorQuery().in(thisInstance);
      query
        .select(`#conversation-actions-menu`)
        .boundingClientRect((data) => {
          if (data) {
            // check if actionsMenu is exceed bottom of the screen
            if (data.bottom > TUIGlobal?.getWindowInfo?.().windowHeight) {
              _actionsMenuPosition.value = {
                ...props.actionsMenuPosition,
                top:
                  props.actionsMenuPosition.top
                  - (props.actionsMenuPosition.conversationHeight || 0)
                  - data.height,
              };
            }
            // check if actionsMenu is exceed right of the screen
            if (_actionsMenuPosition.value.left + data.width + 5 > TUIGlobal.getWindowInfo().windowWidth) {
              _actionsMenuPosition.value.left = TUIGlobal.getWindowInfo().windowWidth - data.width - 5;
            }
          }
          isHiddenActionsMenu.value = false;
        })
        .exec();
    } else {
      // Handling the situation where the native Vue menu is lower than the screen
      const rect = actionsMenuDomRef.value?.getBoundingClientRect();
      // The PC side sets the position of actionsMenu according to the position of the mouse click, otherwise the default value of 167px is used
      if (isPC && typeof props.actionsMenuPosition.left !== 'undefined') {
        _actionsMenuPosition.value.left = props.actionsMenuPosition.left;
      }
      if (rect && rect.bottom > window.innerHeight) {
        _actionsMenuPosition.value.top
          = props.actionsMenuPosition.top
          - (props.actionsMenuPosition.conversationHeight || 0)
          - rect.height;
      }
      isHiddenActionsMenu.value = false;
    }
  });
}

const handleItem = (params: { name: string }) => {
  const { name } = params;
  const conversationModel = currentConversation;
  if (!name || !conversationModel || !conversationModel.conversationID) {
    return;
  }
  switch (name) {
    case CONV_OPERATION.DELETE:
      conversationModel?.deleteConversation();
      break;
    case CONV_OPERATION.ISPINNED:
      conversationModel?.pinConversation();
      break;
    case CONV_OPERATION.DISPINNED:
      conversationModel?.pinConversation();
      break;
    case CONV_OPERATION.MUTE:
      conversationModel?.muteConversation();
      break;
    case CONV_OPERATION.NOTMUTE:
      conversationModel?.muteConversation();
      break;
  }
  emits('closeConversationActionMenu');
};

const deleteConversation = () => {
  isShowDeleteConversationDialog.value = true;
};

const updateShowDeleteConversationDialog = (isShow: boolean) => {
  if (!isShow) {
    emits('closeConversationActionMenu');
  }
  isShowDeleteConversationDialog.value = isShow;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Overlay, {
    maskColor: 'transparent',
    onOnOverlayClick: _cache[6] || (_cache[6] = () => emits('closeConversationActionMenu'))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: "conversation-actions-menu",
        ref_key: "actionsMenuDomRef",
        ref: actionsMenuDomRef,
        class: _normalizeClass([
        _unref(isPC) && 'actions-menu-pc',
        'actions-menu',
        !_unref(isHiddenActionsMenu) && 'cancel-hidden',
      ]),
        style: _normalizeStyle({
        top: `${_unref(_actionsMenuPosition).top}px`,
        left: `${_unref(_actionsMenuPosition).left}px`,
      })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['actions-menu-item']),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (deleteConversation()), ["stop"]))
        }, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.删除会话")), 1),
        (!(props.selectedConversation && props.selectedConversation.isPinned))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['actions-menu-item']),
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleItem({ name: _unref(CONV_OPERATION).ISPINNED })), ["stop"]))
            }, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.置顶会话")), 1))
          : _createCommentVNode("", true),
        (props.selectedConversation && props.selectedConversation.isPinned)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['actions-menu-item']),
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleItem({ name: _unref(CONV_OPERATION).DISPINNED })), ["stop"]))
            }, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.取消置顶")), 1))
          : _createCommentVNode("", true),
        (!(props.selectedConversation && props.selectedConversation.isMuted))
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(['actions-menu-item']),
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (handleItem({ name: _unref(CONV_OPERATION).MUTE })), ["stop"]))
            }, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.消息免打扰")), 1))
          : _createCommentVNode("", true),
        (props.selectedConversation && props.selectedConversation.isMuted)
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(['actions-menu-item']),
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (handleItem({ name: _unref(CONV_OPERATION).NOTMUTE })), ["stop"]))
            }, _toDisplayString(_unref(TUITranslateService).t("TUIConversation.取消免打扰")), 1))
          : _createCommentVNode("", true)
      ], 6),
      _createVNode(Dialog, {
        show: _unref(isShowDeleteConversationDialog),
        center: true,
        isHeaderShow: _unref(isPC),
        onSubmit: _cache[5] || (_cache[5] = ($event: any) => (handleItem({ name: _unref(CONV_OPERATION).DELETE }))),
        "onUpdate:show": updateShowDeleteConversationDialog
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(TUITranslateService).t(_unref(deleteConversationDialogTitle))), 1)
        ]),
        _: 1
      }, 8, ["show", "isHeaderShow"])
    ]),
    _: 1
  }))
}
}

})