import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "manage-header"
}
const _hoisted_2 = { class: "manage-header-content" }
const _hoisted_3 = {
  key: 1,
  class: "main"
}
const _hoisted_4 = { class: "user-info space-top" }
const _hoisted_5 = { class: "user-info-header-left" }
const _hoisted_6 = { class: "user-info-header-right" }
const _hoisted_7 = { class: "span" }
const _hoisted_8 = { class: "user-info-list" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "user-info-list-item-info" }
const _hoisted_12 = {
  key: 0,
  class: "user-info-list-item"
}
const _hoisted_13 = {
  key: 1,
  class: "user-info-list-item"
}
const _hoisted_14 = { class: "aside" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "article" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "list-item" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "groupID" }
const _hoisted_21 = { class: "span" }
const _hoisted_22 = { class: "list-item" }
const _hoisted_23 = { class: "label" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "list-item" }
const _hoisted_26 = { class: "label" }
const _hoisted_27 = { class: "span" }
const _hoisted_28 = { class: "list-item" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "span" }
const _hoisted_31 = { class: "footer list space-top" }
const _hoisted_32 = {
  key: 0,
  class: "del-dialog-title"
}
const _hoisted_33 = {
  key: 1,
  class: "del-dialog-title"
}

import {
  ref,
  computed,
  watchEffect,
  onMounted,
  nextTick,
} from '../../../adapter-vue';
import TUIChatEngine, {
  TUITranslateService,
  TUIGroupService,
  TUIFriendService,
  TUIStore,
  StoreName,
  IGroupModel,
  TUIConversationService,
  IConversationModel,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal, outsideClick } from '@tencentcloud/universal-api';
import MaskLayer from '../../common/MaskLayer/index.vue';
import Dialog from '../../common/Dialog/index.vue';
import Transfer from '../../common/Transfer/index.vue';
import ManageName from './manage-name.vue';
import ManageNotification from './manage-notification.vue';
import ManageMember from './manage-member.vue';
import ManageProfile from './manage-profile.vue';
import ManageAdmin from './manage-admin.vue';
import Icon from '../../common/Icon.vue';
import backSVG from '../../../assets/icon/back.svg';
import rightIcon from '../../../assets/icon/right-icon.svg';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isPC, isUniFrameWork } from '../../../utils/env';
import Server from '../server';
import { enableSampleTaskStatus } from '../../../utils/enableSampleTaskStatus';
import { IFriendProfile, IGroupMember, IUserProfile } from '../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  groupID: {
    type: String,
    default: '',
  },
  groupCurrentTab: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const TUIGroupServer = Server.getInstance();
const TUIConstants = TUIGroupServer.constants;

const props = __props;

const manageRef = ref<any>(undefined);
// type 'profile' | 'member' | 'notification' | 'admin' | ''
const currentTab = ref('');
const editLableName = ref('');
const transferType = ref('');
const mask = ref(false);
const currentGroupID = ref('');
const userInfo = ref({
  list: [] as IGroupMember[],
});
const currentMember = ref<IGroupMember>({});
const typeName = ref({
  [TUIChatEngine.TYPES.GRP_WORK]: '好友工作群',
  [TUIChatEngine.TYPES.GRP_PUBLIC]: '陌生人社交群',
  [TUIChatEngine.TYPES.GRP_MEETING]: '临时会议群',
  [TUIChatEngine.TYPES.GRP_AVCHATROOM]: '直播群',
  [TUIChatEngine.TYPES.GRP_COMMUNITY]: '社群',
  [TUIChatEngine.TYPES.JOIN_OPTIONS_FREE_ACCESS]: '自由加入',
  [TUIChatEngine.TYPES.JOIN_OPTIONS_NEED_PERMISSION]: '需要验证',
  [TUIChatEngine.TYPES.JOIN_OPTIONS_DISABLE_APPLY]: '禁止加群',
});
const member = ref({
  admin: [] as IGroupMember[],
  member: [] as IGroupMember[],
  muteMember: [] as IGroupMember[],
});
const transferList = ref<IGroupMember[]>([]);
const transferTitle = ref('');
const isSearch = ref(false);
const isRadio = ref(false);
const selectedList = ref([]);
const delDialogShow = ref(false);
const groupMemberList = ref<IGroupMember[]>([]);
const deletedUserList = ref([]);
const currentGroup = ref<IGroupModel>();
const currentSelfRole = ref('');
const groupIDValue = ref<string>('');

onMounted(() => {
  nextTick(() => {
    if (manageRef.value && !isUniFrameWork) {
      outsideClick.listen({
        domRefs: manageRef.value,
        handler: handleCompleteManage,
      });
    }
  });
});

TUIStore.watch(StoreName.GRP, {
  currentGroup: (group: IGroupModel) => {
    if (group) {
      currentGroup.value = group;
      currentSelfRole.value = currentGroup.value?.selfInfo?.role;
    }
  },
  currentGroupMemberList: (memberList: IGroupMember[]) => {
    groupMemberList.value = memberList;
    member.value = {
      admin: [],
      member: [],
      muteMember: [],
    };
    Array.from(memberList).map((item: any) => {
      switch (item?.role) {
        case TUIChatEngine.TYPES.GRP_MBR_ROLE_ADMIN:
          member.value.admin.push(item);
          break;
        case TUIChatEngine.TYPES.GRP_MBR_ROLE_MEMBER:
          member.value.member.push(item);
          break;
        default:
          break;
      }
      return item;
    });
    const time: number = new Date().getTime();
    member.value.muteMember = Array.from(memberList).filter(
      (item: any) => item?.muteUntil * 1000 - time > 0,
    );
  },
});

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    groupIDValue.value = conversation?.groupProfile?.groupID;
  },
});

watchEffect(() => {
  const params = TUIGroupServer.getOnCallParams(TUIConstants.TUIGroup.SERVICE.METHOD.OPEN_GROUP_MANAGEMENT);
  currentGroupID.value = params?.groupID || groupIDValue.value;
  currentTab.value = props.groupCurrentTab;
});

const TabName = computed(() => {
  let name = '';
  switch (currentTab.value) {
    case 'notification':
      name = '群公告';
      break;
    case 'member':
      name = '群成员';
      break;
    case 'profile':
      name = '群成员';
      break;
    default:
      name = '群管理';
      break;
  }
  return name;
});

const isOwner = computed(() => {
  // Determine whether the person is the group owner/administrator
  const userRole = currentGroup.value?.selfInfo?.role;
  return userRole === TUIChatEngine.TYPES.GRP_MBR_ROLE_OWNER;
});

const isAdmin = computed(() => {
  const userRole = currentGroup.value?.selfInfo?.role;
  return userRole === TUIChatEngine.TYPES.GRP_MBR_ROLE_OWNER;
});

const isWorkGroup = computed(() => {
  return currentGroup.value?.type === TUIChatEngine.TYPES.GRP_WORK;
});

const isSetMuteTime = computed(() => {
  if (isWorkGroup.value || !(isOwner.value || isAdmin.value)) {
    return false;
  }
  return true;
});

const canDelMember = computed(() => {
  const groupType = currentGroup?.value?.type;
  const isAVChatRoom = groupType === TUIChatEngine.TYPES.GRP_AVCHATROOM;
  if (isAVChatRoom) {
    return false;
  }
  return true;
});

const updateProfile = async (newGroupProfile: any) => {
  const { key, value } = newGroupProfile;
  const options: any = {
    groupID: currentGroup.value.groupID,
    [key]: value,
  };
  TUIGroupService.updateGroupProfile(options)
    .then((res: any) => {
      currentGroup.value = res.data.group;
      editLableName.value = '';
    })
    .catch((error: any) => {
      Toast({
        message: error?.message,
        type: TOAST_TYPE.ERROR,
      });
    });
};

const setCurrentTab = (tabName: string) => {
  currentTab.value = tabName;
  editLableName.value = '';
  if (currentTab.value === 'member') {
    transferType.value = 'remove';
  }
  if (!currentTab.value) {
    transferType.value = '';
  }
};

const cancel = () => {
  toggleMask();
};

const toggleMask = async (type?: string) => {
  selectedList.value = [];
  let memberUserIDList: string[] = [];
  switch (type) {
    case 'add':
      isRadio.value = false;
      memberUserIDList = [...member.value.admin, ...member.value.member].map((item: IUserProfile) => item.userID);
      transferList.value = (await friendList()).filter((item: IFriendProfile) => {
        return item.userID && memberUserIDList.indexOf(item.userID) < 0;
      });
      transferTitle.value = '添加成员';
      break;
    case 'remove':
      isRadio.value = false;
      transferList.value = groupMemberList.value.filter(
        (item: any) => item.userID !== currentGroup?.value?.selfInfo?.userID,
      );
      transferTitle.value = '删除成员';
      break;
    case 'addAdmin':
      isRadio.value = true;
      transferList.value = member.value.member;
      transferTitle.value = '新增管理员';
      break;
    case 'removeAdmin':
      isRadio.value = true;
      transferList.value = member.value.admin;
      transferTitle.value = '移除管理员';
      break;
    case 'changeOwner':
      isRadio.value = true;
      transferList.value = [...member.value.admin, ...member.value.member];
      transferTitle.value = '转让群组';
      break;
    case 'addMute':
      isRadio.value = true;
      transferList.value = member.value.member;
      if (currentGroup.value.selfInfo.role === 'Owner') {
        transferList.value = [...member.value.admin, ...member.value.member];
      }
      transferList.value = transferList?.value?.filter((item: any) => {
        return member?.value?.muteMember?.indexOf(item) < 0;
      });
      transferTitle.value = '新增禁言用户';
      break;
    case 'removeMute':
      isRadio.value = true;
      transferList.value = member.value.muteMember;
      transferTitle.value = '移除禁言用户';
      break;
    default:
      break;
  }
  type && (transferType.value = type);
  mask.value = !mask.value;
};

const friendList = async () => {
  const imResponse = await TUIFriendService.getFriendList();
  const friendList = imResponse.data.map((item: any) => item?.profile);
  return friendList.filter(
    (item: any) =>
      !userInfo.value.list.some(
        (infoItem: any) => infoItem.userID === item.userID,
      ),
  );
};

const canIDissmissGroup = computed(() => {
  const userRole = currentGroup?.value?.selfInfo?.role;
  const groupType = currentGroup?.value?.type;

  return (
    userRole === TUIChatEngine.TYPES.GRP_MBR_ROLE_OWNER
    && groupType !== TUIChatEngine.TYPES.GRP_WORK
  );
});

const isShowAddMember = computed(() => {
  const groupType = currentGroup?.value?.type;
  return groupType === TUIChatEngine.TYPES.GRP_WORK;
});

const showUserNum = computed(() => {
  let num = 3;
  if (!isShowAddMember.value) {
    num += 1;
  }
  if (currentGroup?.value?.selfInfo?.role !== 'Owner') {
    num += 1;
  }
  return num;
});

const getMember = async (type?: string) => {
  const groupID = currentGroupID.value;
  const options = {
    groupID,
    count: 100,
    offset: type && type === 'more' ? userInfo.value.list.length : 0,
  };
  await TUIGroupService.getGroupMemberList(options).then((res: any) => {
    if (type && type === 'more') {
      userInfo.value.list = [...userInfo.value.list, ...res.data.memberList];
    } else {
      userInfo.value.list = res.data.memberList;
    }
  });
};

const handleMemberProfileShow = (user: any) => {
  currentMember.value = user;
  setCurrentTab('profile');
};

const submit = (userList: any) => {
  if (transferType.value === 'remove') {
    deletedUserList.value = userList;
    delDialogShow.value = !delDialogShow.value;
  } else {
    handleManage(userList, transferType.value);
  }
  mask.value = false;
};

const dismissGroup = async (group: any) => {
  await TUIGroupService.dismissGroup(group.groupID);
  enableSampleTaskStatus('dismissGroup');
  Toast({
    message: TUITranslateService.t('TUIGroup.群组解散成功'),
    type: TOAST_TYPE.SUCCESS,
  });
  clearGroupInfo();
};

const clearGroupInfo = () => {
  if (isUniFrameWork) {
    TUIGlobal?.switchTab({
      url: '/TUIKit/components/TUIConversation/index',
    });
  } else {
    handleCompleteManage();
    TUIConversationService.switchConversation();
  }
};

const setAllMuteTime = (value: boolean) => {
  // Set up a muting time for all members
  updateProfile({ key: 'muteAllMembers', value });
  if (value) {
    enableSampleTaskStatus('muteGroup');
    Toast({
      message: TUITranslateService.t('TUIGroup.禁言设置成功'),
      type: TOAST_TYPE.SUCCESS,
    });
  } else {
    Toast({
      message: TUITranslateService.t('TUIGroup.取消禁言成功'),
      type: TOAST_TYPE.SUCCESS,
    });
  }
};

const handleSearchMember = async (value: string) => {
  let imResponse: any = {};
  let imMemberResponse: any = {};
  const options: any = {
    groupID: currentGroupID.value,
    userIDList: [value],
  };
  switch (transferType.value) {
    case 'add':
      try {
        imMemberResponse = await TUIGroupService.getGroupMemberProfile(options);
        transferList.value = transferList.value.filter(
          (item: any) => item.userID !== imResponse.data[0]?.userID,
        );
        transferList.value = [...transferList.value, ...imResponse.data];
        if (imMemberResponse?.data?.memberList.length > 0) {
          transferList.value = transferList.value.map((item: any) => {
            if (item.userID === imMemberResponse?.data?.memberList[0].userID) {
              item.isDisabled = true;
            }
            return item;
          });
        }
      } catch (error: any) {
        const message = TUITranslateService.t('TUIGroup.该用户不存在');
        Toast({
          message,
          type: TOAST_TYPE.ERROR,
        });
      }
      break;
    case 'remove':
      try {
        imResponse = await TUIGroupService.getGroupMemberProfile(options);
        if (imResponse.data.memberList.length === 0) {
          const message = TUITranslateService.t('TUIGroup.该用户不在群组内');
          Toast({
            message,
            type: TOAST_TYPE.ERROR,
          });
        }
        transferList.value = transferList.value.filter(
          (item: any) => item.userID !== imResponse?.data?.memberList[0]?.userID,
        );
        if (imResponse?.data?.memberList.length) {
          transferList.value = [
            ...transferList.value,
            ...imResponse.data.memberList,
          ];
        }
      } catch (error: any) {
        const message = TUITranslateService.t('TUIGroup.该用户不存在');
        Toast({
          message,
          type: TOAST_TYPE.ERROR,
        });
      }
      break;
    default:
      break;
  }
};

const handleManage = (userList: any, type: any) => {
  const userIDList: any = [];
  userList.map((item: any) => {
    userIDList.push(item.userID);
    return item;
  });
  switch (type) {
    case 'add':
      addMember(userIDList);
      break;
    case 'remove':
      deleteGroupMember(userIDList);
      break;
    case 'addAdmin':
      handleAdmin(userList[0]);
      break;
    case 'removeAdmin':
      handleAdmin(userList[0]);
      break;
    case 'changeOwner':
      changeOwner(userIDList[0]);
      break;
    case 'addMute':
      setMemberMuteTime(userIDList[0], 'add');
      break;
    case 'removeMute':
      setMemberMuteTime(userIDList[0], 'remove');
      break;
    default:
      break;
  }
};

const addMember = async (userIDList: any) => {
  const options: any = {
    groupID: currentGroupID.value,
    userIDList,
  };
  await TUIGroupService.addGroupMember(options);
};

const changeOwner = async (userID: any) => {
  const options: any = {
    groupID: currentGroupID.value,
    newOwnerID: userID,
  };
  const imResponse = await TUIGroupService.changeGroupOwner(options);
  currentGroup.value = {};
  currentGroup.value = imResponse.data.group;
};

const setMemberMuteTime = async (userID: string, type?: string) => {
  const options: any = {
    groupID: currentGroupID.value,
    userID,
    muteTime: type === 'add' ? 60 * 60 * 24 * 30 : 0,
  };
  await TUIGroupService.setGroupMemberMuteTime(options);
};

const handleAdmin = async (user: any) => {
  let role = '';
  switch (user.role) {
    case TUIChatEngine.TYPES.GRP_MBR_ROLE_ADMIN:
      role = TUIChatEngine.TYPES.GRP_MBR_ROLE_MEMBER;
      break;
    case TUIChatEngine.TYPES.GRP_MBR_ROLE_MEMBER:
      role = TUIChatEngine.TYPES.GRP_MBR_ROLE_ADMIN;
      break;
  }
  const options: any = {
    groupID: currentGroupID.value,
    userID: user.userID,
    role,
  };
  await TUIGroupService.setGroupMemberRole(options);
};

const deleteGroupMember = async (userIDList: any) => {
  const options: any = {
    groupID: currentGroupID.value,
    userIDList,
    reason: '',
  };
  await TUIGroupService.deleteGroupMember(options);
};

const quitGroup = async (group: any) => {
  await TUIGroupService.quitGroup(group.groupID);
  clearGroupInfo();
};

const back = () => {
  if (currentTab.value) {
    setCurrentTab('');
  } else {
    handleCompleteManage();
  }
};

const handleCompleteManage = () => {
  TUIStore.update(StoreName.GRP, 'isShowManageComponent', false);
  const callback = TUIGroupServer.getOnCallCallback(TUIConstants.TUIGroup.SERVICE.METHOD.OPEN_GROUP_MANAGEMENT);
  callback && callback();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "manageRef",
    ref: manageRef,
    class: "manage"
  }, [
    (!_unref(isUniFrameWork) || _unref(currentTab) ==='admin')
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createVNode(Icon, {
            file: _unref(backSVG),
            onOnClick: back
          }, null, 8, ["file"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${_unref(TabName)}`)), 1),
          _cache[21] || (_cache[21] = _createElementVNode("div", null, null, -1))
        ]))
      : _createCommentVNode("", true),
    (!_unref(currentTab) || (_unref(isUniFrameWork) && _unref(currentTab) != 'admin'))
      ? (_openBlock(), _createElementBlock("main", _hoisted_3, [
          _createVNode(ManageName, {
            class: "space-top",
            isAuthor: _unref(isOwner) || _unref(isAdmin) || _unref(isWorkGroup),
            data: _unref(currentGroup),
            onUpdate: updateProfile
          }, null, 8, ["isAuthor", "data"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("header", {
              class: "user-info-header",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (setCurrentTab('member')))
            }, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群成员`)), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(currentGroup).memberCount || _unref(groupMemberList).length) + " " + _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.人`)), 1),
                _createVNode(Icon, { file: _unref(rightIcon) }, null, 8, ["file"])
              ])
            ]),
            _createElementVNode("ol", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupMemberList).slice(0, _unref(showUserNum)), (item, index) => {
                return (_openBlock(), _createElementBlock("dl", {
                  key: index,
                  class: "user-info-list-item"
                }, [
                  _createElementVNode("dt", {
                    class: "user-info-list-item-main",
                    onClick: ($event: any) => (handleMemberProfileShow(item))
                  }, [
                    _createElementVNode("img", {
                      class: "avatar",
                      src: 
                  item.avatar ||
                    'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                ,
                      onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                    }, null, 8, _hoisted_10)
                  ], 8, _hoisted_9),
                  _createElementVNode("dd", _hoisted_11, _toDisplayString(item.nick || item.userID), 1)
                ]))
              }), 128)),
              (_unref(isShowAddMember))
                ? (_openBlock(), _createElementBlock("dl", _hoisted_12, [
                    _createElementVNode("dt", {
                      class: "avatar",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleMask('add')))
                    }, " + ")
                  ]))
                : _createCommentVNode("", true),
              (_unref(currentSelfRole) === 'Owner')
                ? (_openBlock(), _createElementBlock("dl", _hoisted_13, [
                    _createElementVNode("dt", {
                      class: "avatar",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleMask('remove')))
                    }, " - ")
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("ul", {
            class: "content list space-top",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (editLableName.value = ''))
          }, [
            _createElementVNode("li", {
              class: "list-item",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (setCurrentTab('notification')))
            }, [
              _createElementVNode("aside", _hoisted_14, [
                _createElementVNode("label", _hoisted_15, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群公告`)), 1),
                _createElementVNode("article", _hoisted_16, _toDisplayString(_unref(currentGroup).notification), 1)
              ]),
              _createVNode(Icon, {
                file: _unref(rightIcon),
                class: "end"
              }, null, 8, ["file"])
            ]),
            ((_unref(isAdmin) || _unref(isOwner)) && _unref(isSetMuteTime))
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "list-item",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (setCurrentTab('admin')))
                }, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群管理`)), 1),
                  _createVNode(Icon, { file: _unref(rightIcon) }, null, 8, ["file"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群ID`)), 1),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(currentGroupID)), 1)
              ])
            ]),
            _createElementVNode("li", _hoisted_22, [
              _createElementVNode("label", _hoisted_23, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群头像`)), 1),
              _createElementVNode("img", {
                class: "avatar",
                src: 
              _unref(currentGroup).avatar ||
                'https://web.sdk.qcloud.com/im/demo/TUIkit/web/img/constomer.svg'
            ,
                onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/im/demo/TUIkit/web/img/constomer.svg'"
              }, null, 8, _hoisted_24)
            ]),
            _createElementVNode("li", _hoisted_25, [
              _createElementVNode("label", _hoisted_26, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群类型`)), 1),
              _createElementVNode("span", _hoisted_27, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${_unref(typeName)[_unref(currentGroup).type]}`)), 1)
            ]),
            _createElementVNode("li", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.加群方式`)), 1),
              _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(TUITranslateService).t(
              `TUIGroup.${_unref(typeName)[_unref(currentGroup).joinOption]}`
            )), 1)
            ])
          ]),
          _createElementVNode("ul", _hoisted_31, [
            (_unref(currentSelfRole) === 'Owner' && _unref(groupMemberList).length > 1)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "list-item",
                  onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (toggleMask('changeOwner')), ["stop"]))
                }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.转让群组`)), 1))
              : _createCommentVNode("", true),
            (_unref(canIDissmissGroup))
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: "list-item",
                  onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (dismissGroup(_unref(currentGroup))), ["stop"]))
                }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.解散群聊`)), 1))
              : (_openBlock(), _createElementBlock("li", {
                  key: 2,
                  class: "list-item",
                  onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (quitGroup(_unref(currentGroup))), ["stop"]))
                }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.退出群组`)), 1))
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(currentTab) === 'member')
      ? (_openBlock(), _createBlock(ManageMember, {
          key: 2,
          self: _unref(currentGroup).selfInfo,
          list: _unref(groupMemberList),
          total: ~~_unref(currentGroup).memberCount,
          isShowDel: _unref(currentSelfRole) === 'Owner' && _unref(canDelMember),
          onMore: _cache[9] || (_cache[9] = ($event: any) => (getMember('more'))),
          onDel: submit,
          onHandleMemberProfileShow: handleMemberProfileShow,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (setCurrentTab('')))
        }, null, 8, ["self", "list", "total", "isShowDel"]))
      : _createCommentVNode("", true),
    (_unref(currentTab) === 'profile')
      ? (_openBlock(), _createBlock(ManageProfile, {
          key: 3,
          userInfo: _unref(currentMember),
          onClose: _cache[11] || (_cache[11] = ($event: any) => (setCurrentTab('')))
        }, null, 8, ["userInfo"]))
      : _createCommentVNode("", true),
    (_unref(currentTab) === 'notification')
      ? (_openBlock(), _createBlock(ManageNotification, {
          key: 4,
          isAuthor: _unref(isOwner) || _unref(isAdmin) || _unref(isWorkGroup),
          data: _unref(currentGroup),
          onUpdate: updateProfile,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (setCurrentTab('')))
        }, null, 8, ["isAuthor", "data"]))
      : _createCommentVNode("", true),
    (_unref(currentTab) === 'admin')
      ? _withDirectives((_openBlock(), _createBlock(ManageAdmin, {
          key: 5,
          isSetMuteTime: _unref(isSetMuteTime),
          member: _unref(member),
          currentGroup: _unref(currentGroup),
          onAddAdmin: _cache[13] || (_cache[13] = ($event: any) => (toggleMask('addAdmin'))),
          onRemoveAdmin: _cache[14] || (_cache[14] = ($event: any) => (toggleMask('removeAdmin'))),
          onSetAllMuteTime: setAllMuteTime,
          onAddMute: _cache[15] || (_cache[15] = ($event: any) => (toggleMask('addMute'))),
          onRemoveMute: _cache[16] || (_cache[16] = ($event: any) => (toggleMask('removeMute'))),
          onClose: _cache[17] || (_cache[17] = ($event: any) => (setCurrentTab('')))
        }, null, 8, ["isSetMuteTime", "member", "currentGroup"])), [
          [_vShow, _unref(isAdmin)]
        ])
      : _createCommentVNode("", true),
    _createVNode(MaskLayer, {
      show: _unref(mask),
      "onUpdate:show": _cache[18] || (_cache[18] = (e) => (mask.value = e))
    }, {
      default: _withCtx(() => [
        _createVNode(Transfer, {
          title: _unref(TUITranslateService).t(`TUIGroup.${_unref(transferTitle)}`),
          list: _unref(transferList),
          isSearch: _unref(isSearch),
          isRadio: _unref(isRadio),
          selectedList: _unref(selectedList),
          isH5: !_unref(isPC),
          onSubmit: submit,
          onCancel: cancel,
          onSearch: handleSearchMember
        }, null, 8, ["title", "list", "isSearch", "isRadio", "selectedList", "isH5"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(Dialog, {
      class: "deleted-dialog",
      title: _unref(TUITranslateService).t(`TUIGroup.删除成员`),
      show: _unref(delDialogShow),
      isH5: !_unref(isPC),
      center: true,
      isHeaderShow: _unref(isPC),
      onSubmit: _cache[19] || (_cache[19] = ($event: any) => (handleManage(_unref(deletedUserList), 'remove'))),
      "onUpdate:show": _cache[20] || (_cache[20] = (e) => (delDialogShow.value = e))
    }, {
      default: _withCtx(() => [
        (_unref(deletedUserList).length === 1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.确定从群聊中删除该成员？`)), 1))
          : _createCommentVNode("", true),
        (_unref(deletedUserList).length > 1)
          ? (_openBlock(), _createElementBlock("p", _hoisted_33, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.确定从群聊中删除所选成员？`)), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "show", "isH5", "isHeaderShow"])
  ], 512))
}
}

})