<template>
  <TUIKit :SDKAppID="1600058144" userID="job_9" :userSig="userSig" id="im"/>
  <TUICallKit
    class="callkit-container"
    :allowedMinimized="true"
    :allowedFullScreen="false"
  /> 
</template>

<script >
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import { TUIKit } from "./TUIKit";
import { TUICallKit } from "@tencentcloud/call-uikit-vue";
import axios from "axios";
// let userSig = ""
// import {route} from ""
// @Options({
//   components: {
//     HelloWorld,
//     TUICallKit,
//     TUIKit,
//   },
// })
export default {
  components: {
    HelloWorld,
    TUICallKit,
    TUIKit,
  },
  data() {
    return {
      userSig: "",
      show:false
    };
  },
  methods:{

 getParameterFromUrl(url, parameter) {
  const regex = new RegExp('[?&]' + parameter + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  },
  created() {
 
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}
body{
  margin: 0;
}
</style>
