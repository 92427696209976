import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

import { ref, withDefaults } from '../../../../../adapter-vue';
import { transformTextWithKeysToEmojiNames } from '../../../../TUIChat/emoji-config';
import { IHighlightContent } from '../../../type';

interface IProps {
  content: IHighlightContent[];
  highlightType: 'font' | 'background';
  displayType: 'info' | 'bubble';
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-abstract-text',
  props: {
    content: { default: () => ([]) as IHighlightContent[] },
    highlightType: { default: 'font' },
    displayType: { default: 'info' }
  },
  setup(__props: any) {

const props = __props;

const contentText = ref<Array<{ text: string; isHighlight: boolean }>>(props.content);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'message-abstract-text',
      `message-abstract-text-${_ctx.highlightType}`,
      `message-abstract-text-${_ctx.displayType}`,
    ])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contentText), (contentItem, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass([(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal'])
      }, _toDisplayString(_unref(transformTextWithKeysToEmojiNames)(contentItem.text)), 3))
    }), 128))
  ], 2))
}
}

})