import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["placeholder"]

import { ref, watch } from '../../../adapter-vue';
import {
  TUITranslateService,
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants } from '@tencentcloud/tui-core';
import { TUIGlobal } from '@tencentcloud/universal-api';
import Icon from '../../common/Icon.vue';
import addSVG from '../../../assets/icon/add.svg';
import backSVG from '../../../assets/icon/back.svg';
import { isPC } from '../../../utils/env';
import { debounce } from '../../../utils/lodash';
import { IContactSearchResult } from '../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const searchingPlaceholder = TUITranslateService.t('TUIContact.输入ID');
const isSearching = ref<boolean>(false);
const searchValue = ref<string>('');
const searchResult = ref<IContactSearchResult>({
  user: {
    label: '联系人',
    list: [],
  },
  group: {
    label: '群聊',
    list: [],
  },
});

const changeContactSearchingStatus = debounce(function (status: boolean) {
  isSearching.value = status;
}, 200);

const search = async () => {
  if (!searchValue.value) {
    return;
  }
  TUICore.callService({
    serviceName: TUIConstants.TUISearch.SERVICE.NAME,
    method: TUIConstants.TUISearch.SERVICE.METHOD.SEARCH_USER,
    params: {
      userID: searchValue.value,
    },
  })
    .then((res: any) => {
      searchResult.value.user.list = res.data;
    })
    .catch((error: any) => {
      searchResult.value.user.list = [];
      console.warn('search user error', error);
    });
  TUICore.callService({
    serviceName: TUIConstants.TUISearch.SERVICE.NAME,
    method: TUIConstants.TUISearch.SERVICE.METHOD.SEARCH_GROUP,
    params: {
      groupID: searchValue.value,
    },
  })
    .then((res: any) => {
      searchResult.value.group.list = [res.data.group];
    })
    .catch((error: any) => {
      searchResult.value.group.list = [];
      console.warn('search group error', error);
    });
};
watch(
  () => searchResult.value,
  () => {
    TUIStore.update(
      StoreName.CUSTOM,
      'currentContactSearchResult',
      searchResult.value,
    );
  },
  {
    deep: true,
    immediate: true,
  },
);
watch(
  () => isSearching.value,
  () => {
    TUIStore.update(
      StoreName.CUSTOM,
      'currentContactSearchingStatus',
      isSearching.value,
    );
    if (isSearching.value) {
      searchValue.value = '';
      searchResult.value.user.list = [];
      searchResult.value.group.list = [];
    }
  },
  {
    deep: true,
    immediate: true,
  },
);

TUIGlobal.updateContactSearch = search;
TUIGlobal.closeSearching = () => {
  isSearching.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['tui-contact-search', !_unref(isPC) && 'tui-contact-search-h5'])
  }, [
    (!_unref(isSearching) || !_unref(isPC))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([
        'tui-contact-search-header',
        !_unref(isPC) && 'tui-contact-search-h5-header',
        _unref(isSearching) && 'tui-contact-searching-h5-header',
      ]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(changeContactSearchingStatus)(true)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
          'tui-contact-search-header-icon',
          !_unref(isPC) && 'tui-contact-search-h5-header-icon',
        ]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(changeContactSearchingStatus)(!_unref(isSearching))), ["stop"]))
          }, [
            _createVNode(Icon, {
              file: _unref(isSearching) ? _unref(backSVG) : _unref(addSVG),
              width: _unref(isSearching) ? '20px' : '14px',
              height: _unref(isSearching) ? '20px' : '14px'
            }, null, 8, ["file", "width", "height"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass([
          'tui-contact-search-header-title',
          !_unref(isPC) && 'tui-contact-search-h5-header-title',
        ])
          }, _toDisplayString(_unref(TUITranslateService).t("TUIContact.添加好友/群聊")), 3)
        ], 2))
      : _createCommentVNode("", true),
    (_unref(isSearching))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([
        'tui-contact-search-main',
        !_unref(isPC) && 'tui-contact-search-h5-main',
      ])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(searchValue) ? (searchValue).value = $event : null)),
            class: "tui-contact-search-main-input",
            type: "text",
            placeholder: _unref(searchingPlaceholder),
            enterkeyhint: "search",
            onKeyup: _withKeys(search, ["enter"]),
            onBlur: search,
            onConfirm: search
          }, null, 40, _hoisted_1), [
            [_vModelText, _unref(searchValue)]
          ]),
          _createElementVNode("div", {
            class: "tui-contact-search-main-cancel",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (isSearching.value = false))
          }, _toDisplayString(_unref(TUITranslateService).t("取消")), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})