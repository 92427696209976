import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onUpdate:modelValue", "onConfirm", "onKeyup"]
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

import TUIChatEngine, {
  TUIStore,
  StoreName,
  TUITranslateService,
  IGroupModel,
  Friend,
  FriendApplication,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref, computed, onMounted, onUnmounted } from '../../../adapter-vue';
import { isPC } from '../../../utils/env';

import {
  generateAvatar,
  generateContactInfoName,
  generateContactInfoBasic,
  isFriend,
  isApplicationType,
} from '../utils/index';
import {
  contactMoreInfoConfig,
  contactButtonConfig,
} from './contact-info-config';
import Icon from '../../common/Icon.vue';
import editSVG from '../../../assets/icon/edit.svg';
import backSVG from '../../../assets/icon/back.svg';
import SwitchBar from '../../common/SwitchBar/index.vue';
import {
  IBlackListUserItem,
  IContactInfoMoreItem,
  IContactInfoButton,
} from '../../../interface';
import {
  CONTACT_INFO_LABEL_POSITION,
  CONTACT_INFO_MORE_EDIT_TYPE,
  CONTACT_INFO_BUTTON_TYPE,
} from '../../../constant';
import { deepCopy } from '../../TUIChat/utils/utils';

type IContactInfoType = IGroupModel | Friend | FriendApplication | IBlackListUserItem;


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['switchConversation'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const contactInfoData = ref<IContactInfoType>({} as IContactInfoType);
const contactInfoBasicList = ref<Array<{ label: string; data: string }>>([]);
const contactInfoMoreList = ref<IContactInfoMoreItem[]>([]);
const contactInfoButtonList = ref<IContactInfoButton[]>([]);

const setEditing = (item: any) => {
  item.editing = true;
};

const isGroup = computed((): boolean =>
  (contactInfoData.value as IGroupModel)?.groupID ? true : false,
);

const isApplication = computed((): boolean => {
  return isApplicationType(contactInfoData?.value);
});

// is both friend, if is group type always false
const isBothFriend = ref<boolean>(false);

// is group member, including ordinary member, admin, group owner
const isGroupMember = computed((): boolean => {
  return (contactInfoData.value as IGroupModel)?.selfInfo?.userID ? true : false;
});

// is in black list, if is group type always false
const isInBlackList = computed((): boolean => {
  return (
    !isGroup.value
    && blackList.value?.findIndex(
      (item: IBlackListUserItem) =>
        item?.userID === (contactInfoData.value as IBlackListUserItem)?.userID,
    ) >= 0
  );
});

const blackList = ref<IBlackListUserItem[]>([]);

onMounted(() => {
  TUIStore.watch(StoreName.CUSTOM, {
    currentContactInfo: onCurrentContactInfoUpdated,
  });
  TUIStore.watch(StoreName.USER, {
    userBlacklist: onUserBlacklistUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CUSTOM, {
    currentContactInfo: onCurrentContactInfoUpdated,
  });
  TUIStore.unwatch(StoreName.USER, {
    userBlacklist: onUserBlacklistUpdated,
  });
});

const resetContactInfoUIData = () => {
  contactInfoData.value = {} as IContactInfoType;
  contactInfoBasicList.value = [];
  contactInfoMoreList.value = [];
  contactInfoButtonList.value = [];
};

const resetContactSearchingUIData = () => {
  TUIStore.update(StoreName.CUSTOM, 'currentContactInfo', {});
  TUIStore.update(StoreName.CUSTOM, 'currentContactSearchingStatus', false);
  TUIGlobal?.closeSearching && TUIGlobal?.closeSearching();
};

const onContactInfoEmitSubmit = (item: any) => {
  item.editSubmitHandler
  && item.editSubmitHandler({
    item,
    contactInfoData: contactInfoData.value,
    isBothFriend: isBothFriend.value,
    isInBlackList: isInBlackList.value,
  });
};

const onContactInfoButtonClicked = (item: any) => {
  item.onClick
  && item.onClick({
    contactInfoData: contactInfoData.value,
    contactInfoMoreList: contactInfoMoreList.value,
  });
  if (
    item.key === 'enterGroupConversation'
    || item.key === 'enterC2CConversation'
  ) {
    emits('switchConversation', contactInfoData.value);
    resetContactSearchingUIData();
  }
};

const generateMoreInfo = async () => {
  if (!isApplication.value) {
    if (
      (!isGroup.value && !isBothFriend.value && !isInBlackList.value)
      || (isGroup.value
      && !isGroupMember.value
      && (contactInfoData.value as IGroupModel)?.type !== TUIChatEngine?.TYPES?.GRP_AVCHATROOM)
    ) {
      contactMoreInfoConfig.setWords.data = '';
      contactInfoMoreList.value.push(contactMoreInfoConfig.setWords);
    }
    if (!isGroup.value && !isInBlackList.value) {
      contactMoreInfoConfig.setRemark.data
        = (contactInfoData.value as Friend)?.remark || '';
      contactMoreInfoConfig.setRemark.editing = false;
      contactInfoMoreList.value.push(contactMoreInfoConfig.setRemark);
    }
    if (!isGroup.value && (isBothFriend.value || isInBlackList.value)) {
      contactMoreInfoConfig.blackList.data = isInBlackList.value || false;
      contactInfoMoreList.value.push(contactMoreInfoConfig.blackList);
    }
  } else {
    contactMoreInfoConfig.displayWords.data
      = (contactInfoData.value as FriendApplication)?.wording || '';
    contactInfoMoreList.value.push(contactMoreInfoConfig.displayWords);
  }
};

const generateButton = () => {
  if (isInBlackList.value) {
    return;
  }
  if (isApplication.value) {
    if (
      (contactInfoData.value as FriendApplication)?.type
      === TUIChatEngine?.TYPES?.SNS_APPLICATION_SENT_TO_ME
    ) {
      contactInfoButtonList?.value?.push(
        contactButtonConfig.refuseFriendApplication,
      );
      contactInfoButtonList?.value?.push(
        contactButtonConfig.acceptFriendApplication,
      );
    }
  } else {
    if (isGroup.value && isGroupMember.value) {
      switch ((contactInfoData.value as IGroupModel)?.selfInfo?.role) {
        case 'Owner':
          contactInfoButtonList?.value?.push(contactButtonConfig.dismissGroup);
          break;
        default:
          contactInfoButtonList?.value?.push(contactButtonConfig.quitGroup);
          break;
      }
      contactInfoButtonList?.value?.push(
        contactButtonConfig.enterGroupConversation,
      );
    } else if (!isGroup.value && isBothFriend.value) {
      contactInfoButtonList?.value?.push(contactButtonConfig.deleteFriend);
      contactInfoButtonList?.value?.push(
        contactButtonConfig.enterC2CConversation,
      );
    } else {
      if (isGroup.value) {
        contactInfoButtonList?.value?.push(
          (contactInfoData.value as IGroupModel)?.type === TUIChatEngine?.TYPES?.GRP_AVCHATROOM
            ? contactButtonConfig.joinAVChatGroup
            : contactButtonConfig.joinGroup,
        );
      } else {
        contactInfoButtonList?.value?.push(contactButtonConfig.addFriend);
      }
    }
  }
};

function onUserBlacklistUpdated(userBlacklist: IBlackListUserItem[]) {
  blackList.value = userBlacklist;
}

async function onCurrentContactInfoUpdated(contactInfo: IContactInfoType) {
  if (
    contactInfoData.value
    && contactInfo
    && JSON.stringify(contactInfoData.value) === JSON.stringify(contactInfo)
  ) {
    return;
  }
  resetContactInfoUIData();
  // deep clone
  contactInfoData.value = deepCopy(contactInfo) || {};
  if (!contactInfoData.value || Object.keys(contactInfoData.value)?.length === 0) {
    return;
  }
  contactInfoBasicList.value = generateContactInfoBasic(
    contactInfoData.value,
  );
  isBothFriend.value = await isFriend(contactInfoData.value);
  generateMoreInfo();
  generateButton();
  if (contactInfo.infoKeyList) {
    contactInfoMoreList.value = contactInfo.infoKeyList.map((key: string) => {
      return (contactMoreInfoConfig as any)[key];
    });
  }
  if (contactInfo.btnKeyList) {
    contactInfoButtonList.value = contactInfo.btnKeyList.map((key: string) => {
      return (contactButtonConfig as any)[key];
    });
  }
}

return (_ctx: any,_cache: any) => {
  return (typeof _unref(contactInfoData) === 'object' && Object.keys(_unref(contactInfoData)).length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['tui-contact-info', !_unref(isPC) && 'tui-contact-info-h5'])
      }, [
        (!_unref(isPC))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([
        'tui-contact-info-header',
        !_unref(isPC) && 'tui-contact-info-h5-header',
      ])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([
          'tui-contact-info-header-icon',
          !_unref(isPC) && 'tui-contact-info-h5-header-icon',
        ]),
                onClick: resetContactSearchingUIData
              }, [
                _createVNode(Icon, { file: _unref(backSVG) }, null, 8, ["file"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass([
          'tui-contact-info-header-title',
          !_unref(isPC) && 'tui-contact-info-h5-header-title',
        ])
              }, _toDisplayString(_unref(TUITranslateService).t("TUIContact.添加好友/群聊")), 3)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(['tui-contact-info-basic', !_unref(isPC) && 'tui-contact-info-h5-basic'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
          'tui-contact-info-basic-text',
          !_unref(isPC) && 'tui-contact-info-h5-basic-text',
        ])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([
            'tui-contact-info-basic-text-name',
            !_unref(isPC) && 'tui-contact-info-h5-basic-text-name',
          ])
            }, _toDisplayString(_unref(generateContactInfoName)(_unref(contactInfoData))), 3),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactInfoBasicList), (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.label,
                class: _normalizeClass([
            'tui-contact-info-basic-text-other',
            !_unref(isPC) && 'tui-contact-info-h5-basic-text-other',
          ])
              }, _toDisplayString(`${_unref(TUITranslateService).t(`TUIContact.${item.label}`)}:
          ${item.data}`), 3))
            }), 128))
          ], 2),
          _createElementVNode("img", {
            class: _normalizeClass([
          'tui-contact-info-basic-avatar',
          !_unref(isPC) && 'tui-contact-info-h5-basic-avatar',
        ]),
            src: _unref(generateAvatar)(_unref(contactInfoData))
          }, null, 10, _hoisted_1)
        ], 2),
        (_unref(contactInfoMoreList)[0])
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['tui-contact-info-more', !_unref(isPC) && 'tui-contact-info-h5-more'])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactInfoMoreList), (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.key,
                  class: _normalizeClass([
          'tui-contact-info-more-item',
          !_unref(isPC) && 'tui-contact-info-h5-more-item',
          item.labelPosition === _unref(CONTACT_INFO_LABEL_POSITION).TOP
            ? 'tui-contact-info-more-item-top'
            : 'tui-contact-info-more-item-left',
        ])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass([
            'tui-contact-info-more-item-label',
            !_unref(isPC) && 'tui-contact-info-h5-more-item-label',
          ])
                  }, _toDisplayString(`${_unref(TUITranslateService).t(`TUIContact.${item.label}`)}`), 3),
                  _createElementVNode("div", {
                    class: _normalizeClass([
            'tui-contact-info-more-item-content',
            !_unref(isPC) && 'tui-contact-info-h5-more-item-content',
          ])
                  }, [
                    (!item.editing)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass([
              'tui-contact-info-more-item-content-text',
              !_unref(isPC) && 'tui-contact-info-h5-more-item-content-text',
            ])
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass([
                'tui-contact-info-more-item-content-text-data',
                !_unref(isPC) && 'tui-contact-info-h5-more-item-content-text-data',
              ])
                          }, _toDisplayString(item.data), 3),
                          (item.editable)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass([
                'tui-contact-info-more-item-content-text-icon',
                !_unref(isPC) && 'tui-contact-info-h5-more-item-content-text-icon',
              ]),
                                onClick: ($event: any) => (setEditing(item))
                              }, [
                                _createVNode(Icon, {
                                  file: _unref(editSVG),
                                  width: "14px",
                                  height: "14px"
                                }, null, 8, ["file"])
                              ], 10, _hoisted_2))
                            : _createCommentVNode("", true)
                        ], 2))
                      : (item.editType === _unref(CONTACT_INFO_MORE_EDIT_TYPE).INPUT)
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 1,
                            "onUpdate:modelValue": ($event: any) => ((item.data) = $event),
                            class: _normalizeClass([
              'tui-contact-info-more-item-content-input',
              !_unref(isPC) && 'tui-contact-info-h5-more-item-content-input',
            ]),
                            type: "text",
                            onConfirm: ($event: any) => (onContactInfoEmitSubmit(item)),
                            onKeyup: _withKeys(($event: any) => (onContactInfoEmitSubmit(item)), ["enter"])
                          }, null, 42, _hoisted_3)), [
                            [_vModelText, item.data]
                          ])
                        : (item.editType === _unref(CONTACT_INFO_MORE_EDIT_TYPE).TEXTAREA)
                          ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                              key: 2,
                              "onUpdate:modelValue": ($event: any) => ((item.data) = $event),
                              class: _normalizeClass([
              'tui-contact-info-more-item-content-textarea',
              !_unref(isPC) && 'tui-contact-info-h5-more-item-content-textarea',
            ]),
                              "confirm-type": "done"
                            }, null, 10, _hoisted_4)), [
                              [_vModelText, item.data]
                            ])
                          : (item.editType === _unref(CONTACT_INFO_MORE_EDIT_TYPE).SWITCH)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 3,
                                onClick: ($event: any) => (onContactInfoEmitSubmit(item))
                              }, [
                                _createVNode(SwitchBar, {
                                  value: item.data
                                }, null, 8, ["value"])
                              ], 8, _hoisted_5))
                            : _createCommentVNode("", true)
                  ], 2)
                ], 2))
              }), 128))
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([
        'tui-contact-info-button',
        !_unref(isPC) && 'tui-contact-info-h5-button',
      ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(contactInfoButtonList), (item) => {
            return (_openBlock(), _createElementBlock("button", {
              key: item.key,
              class: _normalizeClass([
          'tui-contact-info-button-item',
          !_unref(isPC) && 'tui-contact-info-h5-button-item',
          item.type === _unref(CONTACT_INFO_BUTTON_TYPE).CANCEL
            ? `tui-contact-info-button-item-cancel`
            : `tui-contact-info-button-item-submit`,
        ]),
              onClick: ($event: any) => (onContactInfoButtonClicked(item))
            }, _toDisplayString(_unref(TUITranslateService).t(`TUIContact.${item.label}`)), 11, _hoisted_6))
          }), 128))
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
}

})