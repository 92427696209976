import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['service'])
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['service-header'])
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['service-header-title'])
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['service-list'])
}
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: /*@__PURE__*/_normalizeClass(['service-list-item-key'])
}
const _hoisted_8 = {
  class: /*@__PURE__*/_normalizeClass(['service-description', 'description'])
}
const _hoisted_9 = {
  key: 1,
  class: "evaluate"
}
const _hoisted_10 = {
  class: /*@__PURE__*/_normalizeClass(['evaluate-description', 'description'])
}
const _hoisted_11 = {
  key: 0,
  class: "evaluate-list"
}
const _hoisted_12 = {
  key: 2,
  class: "order"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "order-main" }
const _hoisted_15 = { class: "order-main-title" }
const _hoisted_16 = {
  class: /*@__PURE__*/_normalizeClass(['order-main-description', 'description'])
}
const _hoisted_17 = { class: "order-main-price" }
const _hoisted_18 = {
  key: 3,
  class: "text-link"
}
const _hoisted_19 = {
  class: /*@__PURE__*/_normalizeClass(['text-link-description', 'description'])
}
const _hoisted_20 = ["href"]
const _hoisted_21 = { key: 4 }

import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { ref, computed, withDefaults } from '../../../../../adapter-vue';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../../constant';
import { JSONToObject, isUrl } from '../../../../../utils/index';
import Icon from '../../../../common/Icon.vue';
import star from '../../../../../assets/icon/star-light.png';
import { IHighlightContent } from '../../../type';
import { ISearchResultListItem } from '../../../../../interface';
interface IProps {
  contentText: IHighlightContent[];
  message: IMessageModel | ISearchResultListItem;
  messageContent: Record<string, unknown> | undefined;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-abstract-custom',
  props: {
    contentText: { default: () => ([]) as IHighlightContent[] },
    message: { default: () => ({}) as IMessageModel },
    messageContent: { default: () => ({}) as Record<string, unknown> }
  },
  setup(__props: any) {

const props = __props;

const custom = ref<{ data?: string; description?: string; extension?: string }>(
  (props?.message as IMessageModel)?.payload,
);
const extensionJSON = computed(() => custom?.value?.data ? JSONToObject(custom.value.data) : custom?.value?.data);
const businessID = computed(() => extensionJSON?.value?.businessID);
const descriptionForShow = ref<Array<{ text: string; isHighlight: boolean }>>(props?.contentText);
const defaultMessageContent = ref<string>(props?.messageContent?.custom as string || '[自定义消息]');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message-abstract-custom']),
    onClickCapture: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, [
    (_unref(businessID) === _unref(CHAT_MSG_CUSTOM_TYPE).SERVICE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_unref(extensionJSON).title), 1),
            (_unref(extensionJSON).hyperlinks_text)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: _normalizeClass(['service-header-link', 'link']),
                  href: _unref(extensionJSON).hyperlinks_text.value,
                  target: "view_window"
                }, _toDisplayString(_unref(extensionJSON).hyperlinks_text.key), 9, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          (_unref(extensionJSON).item && _unref(extensionJSON).item.length > 0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(extensionJSON).item, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: index,
                    class: _normalizeClass(['service-list-item'])
                  }, [
                    (_unref(isUrl)(item.value))
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          class: _normalizeClass(['service-list-item-link', 'link']),
                          href: item.value,
                          target: "view_window"
                        }, _toDisplayString(item.key), 9, _hoisted_6))
                      : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(item.key), 1))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(descriptionForShow), (contentItem, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: _normalizeClass([(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal'])
              }, _toDisplayString(contentItem.text), 3))
            }), 128))
          ])
        ]))
      : (_unref(businessID) === _unref(CHAT_MSG_CUSTOM_TYPE).EVALUATE)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(descriptionForShow), (contentItem, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: _normalizeClass([(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal'])
                }, _toDisplayString(contentItem.text), 3))
              }), 128))
            ]),
            (_unref(extensionJSON).score)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.max(_unref(extensionJSON).score, 0), (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "evaluate-list-item"
                    }, [
                      _createVNode(Icon, {
                        file: _unref(star),
                        class: "file-icon"
                      }, null, 8, ["file"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("article", null, _toDisplayString(_unref(extensionJSON).comment), 1)
          ]))
        : (_unref(businessID) === _unref(CHAT_MSG_CUSTOM_TYPE).ORDER)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("img", {
                class: "order-image",
                src: _unref(extensionJSON).imageUrl,
                alt: ""
              }, null, 8, _hoisted_13),
              _createElementVNode("main", _hoisted_14, [
                _createElementVNode("h1", _hoisted_15, _toDisplayString(_unref(extensionJSON).title), 1),
                _createElementVNode("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(descriptionForShow), (contentItem, index) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: index,
                      class: _normalizeClass([(contentItem && contentItem.isHighlight) ? 'highlight' : 'normal'])
                    }, _toDisplayString(contentItem.text), 3))
                  }), 128))
                ]),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(extensionJSON).price), 1)
              ])
            ]))
          : (_unref(businessID) === _unref(CHAT_MSG_CUSTOM_TYPE).LINK)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", null, _toDisplayString(_unref(extensionJSON).text), 1)
                ]),
                _createElementVNode("a", {
                  class: _normalizeClass(['link']),
                  href: _unref(extensionJSON).link,
                  target: "view_window"
                }, _toDisplayString(_unref(TUITranslateService).t("message.custom.查看详情>>")), 9, _hoisted_20)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(defaultMessageContent)), 1))
  ], 32))
}
}

})