import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['message-input-toolbar-list-end'])
}

import { ref, computed, onMounted, onUnmounted, watch } from '../../../adapter-vue';
import TUIChatEngine, {
  IConversationModel,
  TUIStore,
  StoreName,
} from '@tencentcloud/chat-uikit-engine';
import TUICore, { ExtensionInfo, TUIConstants } from '@tencentcloud/tui-core';
import EmojiPicker from './emoji-picker/index.vue';
import ImageUpload from './image-upload/index.vue';
import FileUpload from './file-upload/index.vue';
import VideoUpload from './video-upload/index.vue';
import Evaluate from './evaluate/index.vue';
import Words from './words/index.vue';
import ToolbarItemContainer from './toolbar-item-container/index.vue';
import UserSelector from './user-selector/index.vue';
import { isPC, isH5, isUniFrameWork } from '../../../utils/env';
import TUIChatConfig from '../config';
import { enableSampleTaskStatus } from '../../../utils/enableSampleTaskStatus';
import { ToolbarDisplayType } from '../../../interface';

interface IProps {
  displayType: ToolbarDisplayType;
}
interface IEmits {
  (e: 'scrollToLatestMessage'): void;
  (e: 'changeToolbarDisplayType', type: ToolbarDisplayType): void;
  (e: 'insertEmoji', emoji: any): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    displayType: { default: 'none' }
  },
  emits: ["scrollToLatestMessage", "changeToolbarDisplayType", "insertEmoji"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const h5Dialog = ref<HTMLElement>();
const currentConversation = ref<IConversationModel>();
const isGroup = ref<boolean>(false);
const selectorShowType = ref<string>('');
const userSelectorRef = ref();
const emojiPickerRef = ref<InstanceType<typeof EmojiPicker>>();
const currentUserSelectorExtension = ref<ExtensionInfo>();
const currentExtensionList = ref<ExtensionInfo[]>([]);
const featureConfig = TUIChatConfig.getFeatureConfig();
const isRenderedEmojiPicker = ref<boolean>(true);
isRenderedEmojiPicker.value = featureConfig.InputEmoji || featureConfig.InputStickers;

onMounted(() => {
  TUIStore.watch(StoreName.CUSTOM, {
    activeConversation: onActiveConversationUpdate,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CUSTOM, {
    activeConversation: onActiveConversationUpdate,
  });
});

watch(() => props.displayType, (newValue) => {
  if (newValue === 'none') {
    emojiPickerRef.value?.closeEmojiPicker();
  } else {
    emits('scrollToLatestMessage');
  }
});

const onActiveConversationUpdate = (conversationID: string) => {
  if (!conversationID) {
    return;
  }
  if (conversationID !== currentConversation.value?.conversationID) {
    getExtensionList();
    currentConversation.value = TUIStore.getConversationModel(conversationID);
    isGroup.value = conversationID.startsWith(TUIChatEngine.TYPES.CONV_GROUP);
  }
};

const getExtensionList = () => {
  const chatType = TUIChatConfig.getChatType();
  const params: Record<string, boolean | string> = { chatType };
  // Backward compatibility: When callkit does not have chatType judgment, use filterVoice and filterVideo to filter
  if (chatType === TUIConstants.TUIChat.TYPE.CUSTOMER_SERVICE) {
    params.filterVoice = true;
    params.filterVideo = true;
    enableSampleTaskStatus('customerService');
  }
  currentExtensionList.value = [
    ...TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.INPUT_MORE.EXT_ID, params),
  ].filter((extension: ExtensionInfo) => {
    if (extension?.data?.name === 'search') {
      return featureConfig.MessageSearch;
    }
    return true;
  });
};

const extensionListShowInStart = computed<ExtensionInfo[]>(() => {
  if (isPC) {
    const extensionList = currentExtensionList.value.filter((extension: ExtensionInfo) => extension?.data?.name !== 'search');
    return extensionList;
  }
  return currentExtensionList.value;
});

const extensionListShowInEnd = computed<ExtensionInfo[]>(() => {
  if (isPC) {
    const searchExtension = currentExtensionList.value.find(extension => extension?.data?.name === 'search');
    return searchExtension ? [searchExtension] : [];
  }
  return [];
});

// handle extensions onclick
const onExtensionClick = (extension: ExtensionInfo) => {
  switch (extension?.data?.name) {
    case 'voiceCall':
      onCallExtensionClicked(extension, 1);
      break;
    case 'videoCall':
      onCallExtensionClicked(extension, 2);
      break;
    default:
      (extension as any)?.listener?.onClicked(currentConversation.value?._conversation);
      break;
  }
};

const onCallExtensionClicked = (extension: ExtensionInfo, callType: number) => {
  selectorShowType.value = extension?.data?.name;
  if (currentConversation?.value?.type === TUIChatEngine.TYPES.CONV_C2C) {
    extension.listener?.onClicked?.({
      userIDList: [currentConversation?.value?.conversationID?.slice(3)],
      type: callType,
    });
  } else if (isGroup.value) {
    currentUserSelectorExtension.value = extension;
    userSelectorRef?.value?.toggleShow && userSelectorRef.value.toggleShow(true);
  }
};

const genExtensionIcon = (extension: any) => {
  return extension?.icon;
};

const genExtensionText = (extension: any) => {
  return extension?.text;
};

const onUserSelectorSubmit = (selectedInfo: any) => {
  currentUserSelectorExtension.value?.listener?.onClicked?.(selectedInfo);
  currentUserSelectorExtension.value = undefined;
};

const onUserSelectorCancel = () => {
  currentUserSelectorExtension.value = undefined;
};

const insertEmoji = (emojiObj: object) => {
  emits('insertEmoji', emojiObj);
};

const dialogShowInH5 = (dialogDom: any) => {
  if (!isH5) {
    return;
  }
  h5Dialog.value?.appendChild && h5Dialog.value?.appendChild(dialogDom);
};

const dialogCloseInH5 = (dialogDom: any) => {
  if (!isH5) {
    return;
  }
  if (dialogDom) {
    h5Dialog.value?.removeChild && h5Dialog.value?.removeChild(dialogDom);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'message-input-toolbar',
      !_unref(isPC) && 'message-input-toolbar-h5',
      _unref(isUniFrameWork) && 'message-input-toolbar-uni',
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'message-input-toolbar-list',
        !_unref(isPC) && 'message-input-toolbar-h5-list',
        _unref(isUniFrameWork) && 'message-input-toolbar-uni-list',
      ])
    }, [
      (_unref(featureConfig).InputImage)
        ? (_openBlock(), _createBlock(ImageUpload, {
            key: 0,
            imageSourceType: "album"
          }))
        : _createCommentVNode("", true)
    ], 2),
    (_unref(extensionListShowInEnd)[0] && _unref(isPC))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(extensionListShowInEnd), (extension, index) => {
            return (_openBlock(), _createBlock(ToolbarItemContainer, {
              key: index,
              iconFile: genExtensionIcon(extension),
              title: genExtensionText(extension),
              iconWidth: _unref(isUniFrameWork) ? '25px' : '20px',
              iconHeight: _unref(isUniFrameWork) ? '25px' : '20px',
              needDialog: false,
              onOnIconClick: ($event: any) => (onExtensionClick(extension))
            }, null, 8, ["iconFile", "title", "iconWidth", "iconHeight", "onOnIconClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(UserSelector, {
      ref_key: "userSelectorRef",
      ref: userSelectorRef,
      type: _unref(selectorShowType),
      currentConversation: _unref(currentConversation),
      isGroup: _unref(isGroup),
      onSubmit: onUserSelectorSubmit,
      onCancel: onUserSelectorCancel
    }, null, 8, ["type", "currentConversation", "isGroup"]),
    (_unref(isH5))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          ref_key: "h5Dialog",
          ref: h5Dialog,
          class: _normalizeClass(['message-input-toolbar-h5-dialog'])
        }, null, 512))
      : _createCommentVNode("", true)
  ], 2))
}
}

})