import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "group-introduction-list select" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "select-item-type" }
const _hoisted_4 = { class: "select-item-header" }
const _hoisted_5 = { class: "left" }
const _hoisted_6 = { class: "select-item-label" }
const _hoisted_7 = { class: "select-item-detail" }
const _hoisted_8 = ["href"]

import { ref, watchEffect } from '../../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import documentLink from '../../../../utils/documentLink';
import Icon from '../../../common/Icon.vue';
import selectedIcon from '../../../../assets/icon/selected.svg';
import { groupIntroConfig } from './config';
import { isUniFrameWork } from '../../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  groupType: {
    type: String,
    default: '',
  },
},
  emits: ['selectType'],
  setup(__props, { emit: __emit }) {

const props = __props;

const type = groupIntroConfig;

const selectType = ref();

const emit = __emit;

watchEffect(() => {
  selectType.value = props.groupType;
});

const selected = (item: any) => {
  selectType.value = item.type;
  emit('selectType', item.type);
};

const openUrl = (link: string) => {
  if (!isUniFrameWork) {
    TUIGlobal?.open(link);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(type), (item, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: _normalizeClass(["select-item", [_unref(selectType) === item.type && 'selected']]),
        onClick: ($event: any) => (selected(item))
      }, [
        _createElementVNode("main", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("aside", _hoisted_5, [
              _createVNode(Icon, {
                class: "icon",
                file: item.icon
              }, null, 8, ["file"]),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${item.label}`)), 1)
            ]),
            (_unref(selectType) === item.type)
              ? (_openBlock(), _createBlock(Icon, {
                  key: 0,
                  file: _unref(selectedIcon)
                }, null, 8, ["file"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${item.detail}`)), 1),
          _createElementVNode("a", {
            class: "link",
            href: _unref(documentLink).product.url,
            target: "_blank",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (openUrl(_unref(documentLink).product.url)))
          }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.${item.src}`)), 9, _hoisted_8)
        ])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}
}

})