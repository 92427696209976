import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tui-chat-safe-tips"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "message-item" }
const _hoisted_4 = ["onLongpress", "onContextmenu", "onTouchstart", "onTouchend", "onMouseover"]
const _hoisted_5 = { class: "delDialog-title" }

import { ref, nextTick, computed, onMounted, onUnmounted, watch } from '../../../adapter-vue';
import TUIChatEngine, {
  IMessageModel,
  TUIStore,
  StoreName,
  TUITranslateService,
  TUIChatService,
} from '@tencentcloud/chat-uikit-engine';
import TUICore, { TUIConstants } from '@tencentcloud/tui-core';
import { outsideClick, getBoundingClientRect, getScrollInfo } from '@tencentcloud/universal-api';
import { TUIEmojiPlugin } from '@tencentcloud/tui-emoji-plugin';
// import { JoinGroupCard } from '@tencentcloud/call-uikit-vue';
import Link from './link';
import MessageGroupApplication from './message-group-application/index.vue';
import MessageText from './message-elements/message-text.vue';
import MessageImage from './message-elements/message-image.vue';
import MessageAudio from './message-elements/message-audio.vue';
import MessageRecord from './message-elements/message-record/index.vue';
import MessageFile from './message-elements/message-file.vue';
import MessageFace from './message-elements/message-face.vue';
import MessageCustom from './message-elements/message-custom.vue';
import MessageTip from './message-elements/message-tip.vue';
import MessageBubble from './message-elements/message-bubble.vue';
import MessageLocation from './message-elements/message-location.vue';
import MessageTimestamp from './message-elements/message-timestamp.vue';
import MessageVideo from './message-elements/message-video.vue';
import MessageTool from './message-tool/index.vue';
import MessageRevoked from './message-tool/message-revoked.vue';
import MessagePlugin from '../../../plugins/plugin-components/message-plugin.vue';
import ScrollButton from './scroll-button/index.vue';
import ReadReceiptPanel from './read-receipt-panel/index.vue';
import { isPluginMessage } from '../../../plugins/plugin-components/index';
import Dialog from '../../common/Dialog/index.vue';
import ImagePreviewer from '../../common/ImagePreviewer/index.vue';
import ProgressMessage from '../../common/ProgressMessage/index.vue';
import { emojiConfig } from '../emoji-config';
import { isPC, isH5 } from '../../../utils/env';
import chatStorage from '../utils/chatStorage';
import { throttle } from '../../../utils/lodash';
import { isEnabledMessageReadReceiptGlobal, shallowCopyMessage, isCreateGroupCustomMessage, deepCopy } from '../utils/utils';

interface ScrollConfig {
  scrollToMessage?: IMessageModel;
  scrollToBottom?: boolean;
  scrollToOffset?: {
    top?: number;
    bottom?: number;
  };
}

interface IProps {
  isGroup: boolean;
  groupID: string;
  isNotInGroup: boolean;
  isMultipleSelectMode: boolean;
}

interface IEmits {
  (key: 'closeInputToolBar'): void;
  (key: 'toggleMultipleSelectMode'): void;
  (key: 'handleEditor', message: IMessageModel, type: string): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    isGroup: { type: Boolean, default: false },
    groupID: { default: '' },
    isNotInGroup: { type: Boolean, default: false },
    isMultipleSelectMode: { type: Boolean, default: false }
  },
  emits: ["closeInputToolBar", "toggleMultipleSelectMode", "handleEditor"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emits = __emit;
const props = __props;

let groupType: string | undefined;
let observer: IntersectionObserver | null = null;
const sentReceiptMessageIDSet = new Set<string>();
const isOfficial = TUIStore.getData(StoreName.APP, 'isOfficial');
const enabledEmojiPlugin = TUIStore.getData(StoreName.APP, 'enabledEmojiPlugin');

const messageListRef = ref<HTMLElement>();
const messageToolListRef = ref<Array<{ messageToolDom: HTMLElement }>>();
// The messageList displayed on the screen, not including messages where isDeleted is true
const messageList = ref<IMessageModel[]>();
// All messageList, including messages where isDeleted is true
const allMessageList = ref<IMessageModel[]>();
const multipleSelectedMessageIDList = ref<string[]>([]);
const isCompleted = ref(false);
const currentConversationID = ref('');
const currentLastMessage = ref<IMessageModel>();
const nextReqMessageID = ref();
const toggleID = ref('');
const TYPES = ref(TUIChatEngine.TYPES);
const isLongpressing = ref(false);
const messageTarget = ref<IMessageModel>();
const messageElementListRef = ref<HTMLElement[] | null>();
const targetMessageDom = ref<HTMLElement | null>();
const blinkMessageIDList = ref<string[]>([]);
const scrollButtonInstanceRef = ref<InstanceType<typeof ScrollButton>>();
const isShowReadUserStatusPanel = ref<boolean>(false);
const readStatusMessage = ref<IMessageModel>();
const beforeHistoryGetScrollHeight = ref<number>(0);
const isTopMessageDom = ref<boolean>(false);
const audioPlayedMapping = ref<Record<string, boolean>>({});

// image preview
const showImagePreview = ref(false);
const currentImagePreview = ref<IMessageModel>();
const imageMessageList = computed(() =>
  messageList?.value?.filter((item: IMessageModel) => {
    return !item.isRevoked && !item.hasRiskContent && item.type === TYPES.value.MSG_IMAGE;
  }),
);

// resend message dialog
const reSendDialogShow = ref(false);
const resendMessageData = ref();

const isShowEmojiPlugin = computed(() => {
  const msgPopMenuExtensionList = TUICore.getExtensionList(TUIConstants.TUIChat.EXTENSION.MSG_POP_MENU.EXT_ID, {
    enabledEmojiPlugin,
  });
  return msgPopMenuExtensionList.some((item) => {
    return item.text === 'TUIEmojiPlugin';
  });
});

onMounted(() => {
  // Retrieve the information about whether the audio has been played from localStorage
  audioPlayedMapping.value = chatStorage.getChatStorage('audioPlayedMapping') || {};

  TUIStore.watch(StoreName.CHAT, {
    messageList: onMessageListUpdated,
    messageSource: onMessageSourceUpdated,
    isCompleted: isCompletedUpdated,
  });

  TUIStore.watch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdated,
  });

  TUIStore.watch(StoreName.CUSTOM, {
    isShowMessagePopMenu: isShowMessagePopMenuUpdated,
  });
});

onMounted(() => {
  messageListRef.value?.addEventListener('scroll', handelScrollListScroll);
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CHAT, {
    messageList: onMessageListUpdated,
    messageSource: onMessageSourceUpdated,
    isCompleted: isCompletedUpdated,
  });

  TUIStore.unwatch(StoreName.CONV, {
    currentConversationID: onCurrentConversationIDUpdated,
  });

  TUIStore.unwatch(StoreName.CUSTOM, {
    isShowMessagePopMenu: isShowMessagePopMenuUpdated,
  });

  messageListRef.value?.removeEventListener('scroll', handelScrollListScroll);

  if (Object.keys(audioPlayedMapping.value).length > 0) {
    // Synchronize storage about whether the audio has been played when the component is unmounted
    chatStorage.setChatStorage('audioPlayedMapping', audioPlayedMapping.value);
  }

  sentReceiptMessageIDSet.clear();
  observer?.disconnect();
  observer = null;
});

async function onMessageListUpdated(list: IMessageModel[]) {
  observer?.disconnect();
  const oldLastMessage = currentLastMessage.value;
  let hasEmojiReaction = false;
  allMessageList.value = list;
  messageList.value = list.filter((message) => {
    if (message.reactionList?.length && !message.isDeleted) {
      hasEmojiReaction = true;
    }
    return !message.isDeleted;
  });
  if (!messageList.value?.length) {
    currentLastMessage.value = {};
    return;
  }
  const newLastMessage = messageList.value?.[messageList.value?.length - 1];
  if (messageTarget.value) {
    if (
      messageList.value?.findIndex(
        (message: IMessageModel) => message?.ID === messageTarget.value?.ID,
      ) >= 0
    ) {
      const tempMessage = messageTarget.value;
      messageTarget.value = undefined;
      await scrollToPosition({ scrollToMessage: tempMessage });
      await blinkMessage(tempMessage?.ID);
    }
  } else if (beforeHistoryGetScrollHeight.value) {
    await scrollToPosition({
      scrollToOffset: { bottom: beforeHistoryGetScrollHeight.value },
    });
    beforeHistoryGetScrollHeight.value = 0;
  } else if (scrollButtonInstanceRef.value?.isScrollButtonVisible && newLastMessage?.flow === 'in') {
    return;
  } else if (
    newLastMessage?.ID
    && JSON.stringify(oldLastMessage) !== JSON.stringify(newLastMessage)
  ) {
    await scrollToPosition({ scrollToBottom: true });
  } else if (hasEmojiReaction && isCurrentListInBottomPosition()) {
    await scrollToPosition({ scrollToBottom: true });
  }
  currentLastMessage.value = Object.assign({}, newLastMessage);
  if (isEnabledMessageReadReceiptGlobal()) {
    nextTick(() => bindIntersectionObserver());
  }
}

function isCurrentListInBottomPosition() {
  return (
    messageListRef.value
    && typeof messageListRef.value.scrollTop === 'number'
    && typeof messageListRef.value.scrollHeight === 'number'
    && typeof messageListRef.value.clientHeight === 'number'
    && Math.ceil(
      messageListRef.value.scrollTop + messageListRef.value.clientHeight,
    ) >= messageListRef.value.scrollHeight
  );
}

async function scrollToPosition(config: ScrollConfig = {}): Promise<void> {
  return new Promise((resolve, reject) => {
    requestAnimationFrame(() => {
      if (!messageListRef.value) {
        reject();
      }
      const container = messageListRef.value;
      if (config.scrollToBottom) {
        container!.scrollTop = container!.scrollHeight;
      } else if (config.scrollToMessage) {
        const targetMessageDom = messageElementListRef.value?.find(
          (dom: HTMLElement) => dom?.id === `tui-${config.scrollToMessage?.ID}`,
        );
        if (targetMessageDom?.scrollIntoView) {
          targetMessageDom.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      } else if (config.scrollToOffset) {
        if (config.scrollToOffset?.top) {
          container!.scrollTop = config.scrollToOffset.top;
        } else if (config.scrollToOffset?.bottom) {
          container!.scrollTop = container!.scrollHeight - config.scrollToOffset.bottom;
        }
      }
      resolve();
    });
  });
}

async function onMessageSourceUpdated(message: IMessageModel) {
  // messageSource change has two cases
  // 1. messageSource change -> cache miss -> messageList change,
  // 2. messageSource change -> cache hit -> messageList not change
  // Only the second case needs to add scrollToTarget when listening here
  messageTarget.value = message;
  if (messageTarget.value) {
    if (
      messageList.value?.findIndex(
        (message: IMessageModel) => message?.ID === messageTarget.value?.ID,
      ) >= 0
    ) {
      const tempMessage = messageTarget.value;
      messageTarget.value = undefined;
      await scrollToPosition({ scrollToMessage: tempMessage });
      await blinkMessage(tempMessage?.ID);
    }
  }
}

function isCompletedUpdated(flag: boolean) {
  isCompleted.value = flag;
}

function isShowMessagePopMenuUpdated(isShow: boolean) {
  if (!isShow) {
    toggleID.value = '';
  }
}

const onCurrentConversationIDUpdated = (conversationID: string) => {
  currentConversationID.value = conversationID;
  if (!currentConversationID.value) {
    messageList.value = [];
  }
  if (isEnabledMessageReadReceiptGlobal()) {
    const { groupProfile } = TUIStore.getConversationModel(conversationID) || {};
    groupType = groupProfile?.type;
  }
  if (Object.keys(audioPlayedMapping.value).length > 0) {
    // Synchronize storage about whether the audio has been played when converstaion switched
    chatStorage.setChatStorage('audioPlayedMapping', audioPlayedMapping.value);
  }
};

const getHistoryMessageList = () => {
  TUIChatService.getMessageList().then((res: any) => {
    const { nextReqMessageID: ID } = res.data;
    nextReqMessageID.value = ID;
  });
  // After getting the historical messages, keep the scroll bar in the original position
  beforeHistoryGetScrollHeight.value = messageListRef.value?.scrollHeight;
};

const openComplaintLink = (type: any) => {
  window.open(type.url);
};

const handleImagePreview = (message: IMessageModel) => {
  if (showImagePreview.value || currentImagePreview.value || isLongpressing.value) {
    return;
  }
  showImagePreview.value = true;
  currentImagePreview.value = message;
};

const onImagePreviewerClose = () => {
  showImagePreview.value = false;
  currentImagePreview.value = null;
};

// toggle message
const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
  if (props.isMultipleSelectMode || props.isNotInGroup) {
    return;
  }
  if (isLongpress) {
    isLongpressing.value = true;
  }
  toggleID.value = message.ID;
  filterTopMessageDom(e.target);
};

const handleToggleMessageItemForPC = (e: MouseEvent, message: IMessageModel) => {
  if (props.isMultipleSelectMode || props.isNotInGroup) {
    return;
  }
  if (isPC) {
    toggleID.value = message.ID;
    targetMessageDom.value = messageElementListRef.value?.find((dom: HTMLElement) => dom?.id === `tui-${message.ID}`);
    nextTick(() => {
      const ignoreDomRefs = messageToolListRef.value && messageToolListRef.value[0]?.messageToolDom;
      outsideClick.listen({
        domRefs: targetMessageDom.value,
        ignoreDomRefs: ignoreDomRefs,
        handler: closeChatPop,
        button: e.button,
      });
      filterTopMessageDom(e.target);
    });
  }
};

function filterTopMessageDom(toggleMessageElement: any) {
  const chatElement = document.getElementById('tui-chat-main');
  const safeTop = 160;
  const messageElementRect = toggleMessageElement.getBoundingClientRect();
  const ChatElementRect = chatElement.getBoundingClientRect();
  isTopMessageDom.value = messageElementRect.top - ChatElementRect.top < safeTop ? true : false;
}

// h5 long press
let timer: number;
const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
  if (props.isMultipleSelectMode || props.isNotInGroup) {
    return;
  }
  if (!isH5) return;
  function longPressHandler() {
    clearTimeout(timer);
    handleToggleMessageItem(e, message);
  }
  function touchStartHandler() {
    timer = setTimeout(longPressHandler, 500);
  }
  function touchEndHandler() {
    clearTimeout(timer);
  }
  switch (type) {
    case 'touchstart':
      touchStartHandler();
      break;
    case 'touchend':
      touchEndHandler();
      setTimeout(() => {
        isLongpressing.value = false;
      }, 200);
      break;
  }
};

// re-edit message
const handleEdit = (message: IMessageModel) => {
  emits('handleEditor', message, 'reedit');
};

const resendMessage = (message: IMessageModel) => {
  reSendDialogShow.value = true;
  resendMessageData.value = message;
};

const resendMessageConfirm = () => {
  reSendDialogShow.value = !reSendDialogShow.value;
  const messageModel = resendMessageData.value;
  messageModel.resendMessage();
};

function blinkMessage(messageID: string): Promise<void> {
  return new Promise((resolve) => {
    const index = blinkMessageIDList.value.indexOf(messageID);
    if (index < 0) {
      blinkMessageIDList.value.push(messageID);
      const timer = setTimeout(() => {
        blinkMessageIDList.value.splice(blinkMessageIDList.value.indexOf(messageID), 1);
        clearTimeout(timer);
        resolve();
      }, 3000);
    }
  });
}

async function scrollToLatestMessage() {
  const { scrollHeight } = await getScrollInfo('#messageScrollList');
  const { height } = await getBoundingClientRect('#messageScrollList');
  if (messageListRef.value) {
    messageListRef.value.scrollTop = scrollHeight - height;
  }
}

const handelScrollListScroll = throttle(function (e: Event) {
  scrollButtonInstanceRef.value?.judgeScrollOverOneScreen(e);
}, 150, { leading: true });

async function bindIntersectionObserver() {
  if (
    !messageList.value
    || !messageListRef.value
    || messageList.value.length === 0
  ) {
    return;
  }

  if (groupType === TYPES.value.GRP_AVCHATROOM || groupType === TYPES.value.GRP_COMMUNITY) {
    // AVCHATROOM and COMMUNITY chats do not monitor read receipts for messages.
    return;
  }

  const mappingFromIDToMessage: Record<string, {
    msgDom: HTMLElement;
    msgModel: IMessageModel | undefined;
  }> = {};

  observer?.disconnect();
  observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const { isIntersecting, target } = entry;
      if (isIntersecting) {
        const { msgDom, msgModel } = mappingFromIDToMessage[target.id];
        if (
          msgModel
          && !msgModel.readReceiptInfo?.isPeerRead
          && !sentReceiptMessageIDSet.has(msgModel.ID)
        ) {
          TUIChatService.sendMessageReadReceipt([msgModel]);
          sentReceiptMessageIDSet.add(msgModel.ID);
          observer?.unobserve(msgDom);
        }
      }
    });
  }, {
    root: messageListRef.value,
    threshold: 0.7,
  });

  const arrayOfMessageLi = messageListRef.value?.querySelectorAll('.message-li');
  if (arrayOfMessageLi) {
    for (let i = 0; i < arrayOfMessageLi?.length; ++i) {
      const messageElement = arrayOfMessageLi[i] as HTMLElement;
      const matchingMessage = messageList.value.find((message: IMessageModel) => {
        return messageElement.id.slice(4) === message.ID;
      });
      if (
        matchingMessage
        && matchingMessage.needReadReceipt
        && matchingMessage.flow === 'in'
      ) {
        mappingFromIDToMessage[messageElement.id] = {
          msgDom: messageElement,
          msgModel: matchingMessage,
        };
        observer?.observe(messageElement);
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isSignalingMessage = (message: IMessageModel) => {
  return message?.type === TYPES.value.MSG_CUSTOM && message?.getSignalingInfo();
};

function setReadReceiptPanelVisible(visible: boolean, message?: IMessageModel) {
  if (visible && props.isNotInGroup) {
    return;
  }
  if (!visible) {
    readStatusMessage.value = undefined;
  } else {
    readStatusMessage.value = message;
  }
  isShowReadUserStatusPanel.value = visible;
}

function closeChatPop() {
  toggleID.value = '';
}

function onMessageListBackgroundClick() {
  emits('closeInputToolBar');
}

watch(() => props.isMultipleSelectMode, (newValue) => {
  if (!newValue) {
    changeSelectMessageIDList({
      type: 'clearAll',
      messageID: '',
    });
  }
});

function changeSelectMessageIDList({ type, messageID }: { type: 'add' | 'remove' | 'clearAll'; messageID: string }) {
  // TODO need to delete this
  if (type === 'clearAll') {
    multipleSelectedMessageIDList.value = [];
  } else if (type === 'add' && !multipleSelectedMessageIDList.value.includes(messageID)) {
    multipleSelectedMessageIDList.value.push(messageID);
  } else if (type === 'remove') {
    multipleSelectedMessageIDList.value = multipleSelectedMessageIDList.value.filter(id => id !== messageID);
  }
}

function mergeForwardMessage() {
  TUIStore.update(StoreName.CUSTOM, 'multipleForwardMessageID', {
    isMergeForward: true,
    messageIDList: multipleSelectedMessageIDList.value,
  });
}

function oneByOneForwardMessage() {
  TUIStore.update(StoreName.CUSTOM, 'multipleForwardMessageID', {
    isMergeForward: false,
    messageIDList: multipleSelectedMessageIDList.value,
  });
}

function setAudioPlayed(messageID: string) {
  audioPlayedMapping.value = {
    ...audioPlayedMapping.value,
    [messageID]: true,
  };
}

__expose({
  oneByOneForwardMessage,
  mergeForwardMessage,
  scrollToLatestMessage,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tui-chat", [_unref(isH5) ? 'tui-chat-h5' : '']])
  }, [
    _createElementVNode("div", {
      id: "tui-chat-main",
      class: "tui-chat-main",
      onClick: closeChatPop
    }, [
      (_unref(isOfficial))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t(
              "TUIChat.【安全提示】本 APP 仅用于体验腾讯云即时通信 IM 产品功能，不可用于业务洽谈与拓展。请勿轻信汇款、中奖等涉及钱款的信息，勿轻易拨打陌生电话，谨防上当受骗。"
            )), 1),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openComplaintLink(_unref(Link).complaint)))
            }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.点此投诉")), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isGroup)
        ? (_openBlock(), _createBlock(MessageGroupApplication, {
            key: props.groupID,
            groupID: props.groupID
          }, null, 8, ["groupID"]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", {
        id: "messageScrollList",
        ref_key: "messageListRef",
        ref: messageListRef,
        class: "tui-message-list",
        onClick: onMessageListBackgroundClick
      }, [
        (!_unref(isCompleted))
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "message-more",
              onClick: getHistoryMessageList
            }, _toDisplayString(_unref(TUITranslateService).t("TUIChat.查看更多")), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(messageList), (item, index) => {
          return (_openBlock(), _createElementBlock("li", {
            id: 'tui-' + item.ID,
            key: item.ID,
            ref_for: true,
            ref_key: "messageElementListRef",
            ref: messageElementListRef,
            class: "message-li"
          }, [
            _createVNode(MessageTimestamp, {
              currTime: item.time,
              prevTime: index > 0 ? _unref(messageList)[index - 1].time : 0
            }, null, 8, ["currTime", "prevTime"]),
            _createElementVNode("div", _hoisted_3, [
              (item.type === _unref(TYPES).MSG_GRP_TIP || _unref(isCreateGroupCustomMessage)(item))
                ? (_openBlock(), _createBlock(MessageTip, {
                    key: 0,
                    content: item.getMessageContent(),
                    blinkMessageIDList: _unref(blinkMessageIDList),
                    onBlinkMessage: blinkMessage
                  }, null, 8, ["content", "blinkMessageIDList"]))
                : (item.isRevoked)
                  ? (_openBlock(), _createBlock(MessageRevoked, {
                      key: 1,
                      isEdit: item.type === _unref(TYPES).MSG_TEXT,
                      messageItem: _unref(shallowCopyMessage)(item),
                      onMessageEdit: ($event: any) => (handleEdit(item))
                    }, null, 8, ["isEdit", "messageItem", "onMessageEdit"]))
                  : (_unref(isPluginMessage)(item))
                    ? (_openBlock(), _createBlock(MessagePlugin, {
                        key: 2,
                        message: _unref(deepCopy)(item),
                        blinkMessageIDList: _unref(blinkMessageIDList),
                        onResendMessage: resendMessage,
                        onHandleToggleMessageItem: handleToggleMessageItem,
                        onHandleH5LongPress: handleH5LongPress
                      }, null, 8, ["message", "blinkMessageIDList"]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass({
                'message-event-bind-div': true,
              }),
                        onLongpress: ($event: any) => (handleToggleMessageItem($event, item, true)),
                        onContextmenu: _withModifiers(($event: any) => (handleToggleMessageItemForPC($event, item)), ["prevent","right"]),
                        onTouchstart: ($event: any) => (handleH5LongPress($event, item, 'touchstart')),
                        onTouchend: ($event: any) => (handleH5LongPress($event, item, 'touchend')),
                        onMouseover: ($event: any) => (handleH5LongPress($event, item, 'touchend'))
                      }, [
                        _createVNode(MessageBubble, {
                          content: item.getMessageContent(),
                          isAudioPlayed: Boolean(_unref(audioPlayedMapping)[item.ID]),
                          blinkMessageIDList: _unref(blinkMessageIDList),
                          isMultipleSelectMode: _ctx.isMultipleSelectMode,
                          messageItem: JSON.parse(JSON.stringify(item)),
                          multipleSelectedMessageIDList: _unref(multipleSelectedMessageIDList),
                          onBlinkMessage: blinkMessage,
                          onResendMessage: ($event: any) => (resendMessage(item)),
                          onChangeSelectMessageIDList: changeSelectMessageIDList,
                          onSetReadReceiptPanelVisible: setReadReceiptPanelVisible
                        }, {
                          messageElement: _withCtx(() => [
                            (item.type === _unref(TYPES).MSG_TEXT)
                              ? (_openBlock(), _createBlock(MessageText, {
                                  key: 0,
                                  content: item.getMessageContent()
                                }, null, 8, ["content"]))
                              : (item.type === _unref(TYPES).MSG_IMAGE)
                                ? (_openBlock(), _createBlock(ProgressMessage, {
                                    key: 1,
                                    content: item.getMessageContent(),
                                    messageItem: item
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(MessageImage, {
                                        content: item.getMessageContent(),
                                        messageItem: item,
                                        onPreviewImage: handleImagePreview
                                      }, null, 8, ["content", "messageItem"])
                                    ]),
                                    _: 2
                                  }, 1032, ["content", "messageItem"]))
                                : (item.type === _unref(TYPES).MSG_VIDEO)
                                  ? (_openBlock(), _createBlock(ProgressMessage, {
                                      key: 2,
                                      content: item.getMessageContent(),
                                      messageItem: item
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(MessageVideo, {
                                          content: item.getMessageContent(),
                                          messageItem: item
                                        }, null, 8, ["content", "messageItem"])
                                      ]),
                                      _: 2
                                    }, 1032, ["content", "messageItem"]))
                                  : (item.type === _unref(TYPES).MSG_AUDIO)
                                    ? (_openBlock(), _createBlock(MessageAudio, {
                                        key: 3,
                                        content: item.getMessageContent(),
                                        messageItem: item,
                                        onSetAudioPlayed: setAudioPlayed
                                      }, null, 8, ["content", "messageItem"]))
                                    : (item.type === _unref(TYPES).MSG_FILE)
                                      ? (_openBlock(), _createBlock(ProgressMessage, {
                                          key: 4,
                                          content: item.getMessageContent(),
                                          messageItem: item
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(MessageFile, {
                                              content: item.getMessageContent(),
                                              messageItem: item
                                            }, null, 8, ["content", "messageItem"])
                                          ]),
                                          _: 2
                                        }, 1032, ["content", "messageItem"]))
                                      : (item.type === _unref(TYPES).MSG_MERGER)
                                        ? (_openBlock(), _createBlock(MessageRecord, {
                                            key: 5,
                                            renderData: item.payload,
                                            messageItem: item
                                          }, null, 8, ["renderData", "messageItem"]))
                                        : (item.type === _unref(TYPES).MSG_FACE)
                                          ? (_openBlock(), _createBlock(MessageFace, {
                                              key: 6,
                                              content: item.getMessageContent()
                                            }, null, 8, ["content"]))
                                          : (item.type === _unref(TYPES).MSG_LOCATION)
                                            ? (_openBlock(), _createBlock(MessageLocation, {
                                                key: 7,
                                                content: item.getMessageContent()
                                              }, null, 8, ["content"]))
                                            : (item.type === _unref(TYPES).MSG_CUSTOM)
                                              ? (_openBlock(), _createBlock(MessageCustom, {
                                                  key: 8,
                                                  content: item.getMessageContent(),
                                                  messageItem: item
                                                }, null, 8, ["content", "messageItem"]))
                                              : _createCommentVNode("", true)
                          ]),
                          TUIEmojiPlugin: _withCtx(() => [
                            (_unref(isShowEmojiPlugin) && item.reactionList.length > 0)
                              ? (_openBlock(), _createBlock(_unref(TUIEmojiPlugin), {
                                  key: 0,
                                  type: "reaction-detail",
                                  emojiConfig: _unref(emojiConfig),
                                  message: _unref(shallowCopyMessage)(item)
                                }, null, 8, ["emojiConfig", "message"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["content", "isAudioPlayed", "blinkMessageIDList", "isMultipleSelectMode", "messageItem", "multipleSelectedMessageIDList", "onResendMessage"])
                      ], 40, _hoisted_4)),
              (item.ID === _unref(toggleID))
                ? (_openBlock(), _createBlock(MessageTool, {
                    key: 4,
                    ref_for: true,
                    ref_key: "messageToolListRef",
                    ref: messageToolListRef,
                    class: _normalizeClass({
                'message-tool': true,
                'message-tool-out': item.flow === 'out',
                'message-tool-in': item.flow === 'in',
                'message-tool-bottom': _unref(isTopMessageDom)
              }),
                    messageItem: item,
                    isMultipleSelectMode: _ctx.isMultipleSelectMode,
                    onToggleMultipleSelectMode: _cache[1] || (_cache[1] = () => emits('toggleMultipleSelectMode'))
                  }, {
                    TUIEmojiPlugin: _withCtx(() => [
                      (_unref(isShowEmojiPlugin))
                        ? (_openBlock(), _createBlock(_unref(TUIEmojiPlugin), {
                            key: 0,
                            message: item,
                            emojiConfig: _unref(emojiConfig)
                          }, null, 8, ["message", "emojiConfig"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["class", "messageItem", "isMultipleSelectMode"]))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_2))
        }), 128))
      ], 512),
      _createVNode(ScrollButton, {
        ref_key: "scrollButtonInstanceRef",
        ref: scrollButtonInstanceRef,
        onScrollToLatestMessage: scrollToLatestMessage
      }, null, 512),
      (_unref(reSendDialogShow))
        ? (_openBlock(), _createBlock(Dialog, {
            key: 2,
            class: "resend-dialog",
            show: _unref(reSendDialogShow),
            isH5: !_unref(isPC),
            center: true,
            isHeaderShow: _unref(isPC),
            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (resendMessageConfirm())),
            "onUpdate:show": _cache[3] || (_cache[3] = (e) => (reSendDialogShow.value = e))
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(TUITranslateService).t("TUIChat.确认重发该消息？")), 1)
            ]),
            _: 1
          }, 8, ["show", "isH5", "isHeaderShow"]))
        : _createCommentVNode("", true),
      (_unref(showImagePreview))
        ? (_openBlock(), _createBlock(ImagePreviewer, {
            key: 3,
            currentImage: _unref(currentImagePreview),
            imageList: _unref(imageMessageList),
            onClose: onImagePreviewerClose
          }, null, 8, ["currentImage", "imageList"]))
        : _createCommentVNode("", true),
      (_unref(isShowReadUserStatusPanel))
        ? (_openBlock(), _createBlock(ReadReceiptPanel, {
            key: 4,
            message: Object.assign({}, _unref(readStatusMessage)),
            onSetReadReceiptPanelVisible: setReadReceiptPanelVisible
          }, null, 8, ["message"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})