import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Loading from '../../../common/Loading/index.vue';
import { isPC } from '../../../../utils/env';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['search-result-loading', !_unref(isPC) && 'search-result-loading-h5'])
  }, [
    _createVNode(Loading, {
      width: "40px",
      height: "40px"
    })
  ], 2))
}
}

})