import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  width: {
    type: String,
    default: '30px',
  },
  height: {
    type: String,
    default: '30px',
  },
  color: {
    type: String,
    default: '#D9D9D9',
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "tui-loading",
    style: _normalizeStyle({
      width: props.width,
      height: props.height,
      border: `2px solid ${props.color}`,
      borderTopColor: 'transparent',
    })
  }, null, 4))
}
}

})